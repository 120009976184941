import styled from 'styled-components';
import * as Views from '../../../../config/views';

export const Item = styled.div`
	position: relative;
	margin: 0;

	display: flex;
	flex-direction: column;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-left: 38px;
		border-left: 1px solid #000;
		padding-bottom: 50px;
	
		&:last-child {
			border-left: none;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		padding-left: 150px;
		border-left: 1px solid #000;
		padding-bottom: 50px;
	
		&:last-child {
			border-left: none;
		}
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		padding-left: 167px;
		border-left: 1px solid #000;
		padding-bottom: 63px;

		&:last-child {
			border-left: none;
		}
    }
`

export const Pin = styled.img`
	position: absolute;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {        
		display: none;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		display: block;  
		top: 0;
		left: -12px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		display: block;
		top: 0;
		left: -12px;
	}
`

export const PinM = styled.img`
	position: absolute;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {        
		display: block;
		left: -9px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		top: 0;
		left: -12px;
		display: none;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		top: 0;
		left: -12px;
		display: none;
	}
`

export const Years = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: var(--generalGray);	
	position: absolute;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		top: -22px;
		left: 38px;
		font-size: 12px;
		line-height: 14px;	
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		top: 2.5px;
		left: 30px;
		font-size: 14px;
		line-height: 16px;	
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		top: 2.5px;
		left: 30px;
		font-size: 14px;
		line-height: 16px;		
	}
`

export const TitleBox = styled.div`
	display: flex;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		margin-bottom: 2px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		margin-bottom: 5px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 6px;
	}
`

export const CompanyLink = styled.a`
	text-decoration: none;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.02em;
	color: var(--generalBlue);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		font-size: 18px;
		line-height: 21px;	
		margin-right: 4px;  
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		font-size: 22px;
		line-height: 26px;	
		margin-right: 5px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 22px;
		line-height: 26px;	
		margin-right: 6px;
	}
`

export const Company = styled.a`
	text-decoration: none;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.02em;
	color: var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		font-size: 18px;
		line-height: 21px;	
		margin-right: 4px;  
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		font-size: 22px;
		line-height: 26px;	
		margin-right: 5px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 22px;
		line-height: 26px;	
		margin-right: 6px;
	}
`

export const Location = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.02em;
	color: var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		font-size: 18px;
		line-height: 21px;	
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		font-size: 22px;
		line-height: 26px;	
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 22px;
		line-height: 26px;	
	}
`

export const JobTitle = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;

	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {      
		max-width: 281px;
		padding-bottom: 12px;
		margin-bottom: 15px;
		border-bottom: 1px solid #EAEAEA;
		font-size: 16px;
		line-height: 19px;	
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		max-width: 400px;
		padding-bottom: 12px;
		margin-bottom: 15px;
		border-bottom: 1px solid #EAEAEA;
		font-size: 18px;
		line-height: 21px;	
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		max-width: 419px;
		padding-bottom: 12px;
		margin-bottom: 20px;
		border-bottom: 1px solid #EAEAEA;
		font-size: 18px;
		line-height: 21px;
    }
`
