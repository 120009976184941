import React from 'react';
import { ANA_DATA, bannerPropsAna } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Analitycs = () => {
	return (
		<Template 
			data={ANA_DATA}
			Banner={Banner}
			bannerProps={bannerPropsAna}
		/>
	)
}
