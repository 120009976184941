import React, { FC } from 'react';
import * as Styled from './styled';
import { IGrid } from './types';

export const Grid: FC<IGrid> = ({ children }) => {
  return (
	<Styled.Grid>
		{children}
	</Styled.Grid>
  )
}
