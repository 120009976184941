import React, { FC, useState, useEffect } from 'react';
import * as Styled from './styled';
import { IOne } from './types';

export const One: FC<IOne> = ({
	$fill,
	$stroke,
	$shadow,
	$format,
	$height,
	$image,
	$openView
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	useEffect(() => {
		if (document.body.clientWidth > 1439) {
			if (isOpenModal) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		}
	}, [isOpenModal]);

	const handleOpenModal = () => {
		setIsOpenModal(prev => !prev);
	}

	return (
		<Styled.One 
			fill={$fill} 
			border={$stroke}
			shadow={$shadow}
		>
			{$openView[0].length > 0
				? (
					<Styled.OneImg
						img={$image}
						format={$format}
						heightM={$height[2]}
						heightT={$height[1]}
						heightD={$height[0]}
						cursor="true"
						onClick={handleOpenModal}
					/>
				)
				: (
					<Styled.OneImg
						img={$image}
						format={$format}
						heightM={$height[2]}
						heightT={$height[1]}
						heightD={$height[0]}
						cursor="false"
					/>
				)
			}
			{isOpenModal && (
				<Styled.ModalBox>
					<Styled.Modal>
						<Styled.CloseBar>
							<Styled.Close onClick={handleOpenModal} />
						</Styled.CloseBar>
						<Styled.ModalImg img={$openView[0]} height={$openView[1]} format={$format} />
					</Styled.Modal>
				</Styled.ModalBox>
			)}
		</Styled.One>
	);
}
