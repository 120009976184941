import React, { FC } from 'react';
import * as Styled from './styled';
import { IEmptyBanner } from './types';

export const EmptyBanner: FC<IEmptyBanner> = ({ title, description }) => {
	return (
		<Styled.BannerBox>
			<Styled.Banner>
				<Styled.Title>{title}</Styled.Title>
				<Styled.Descr>{description}</Styled.Descr>
			</Styled.Banner>
		</Styled.BannerBox>
	)
}
