import styled from 'styled-components';
import * as Views from '../../config/views';

export const CompanyBox = styled.div`
	display: flex;
	flex-direction: column;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		margin-bottom: 48px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		margin-bottom: 35px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-bottom: 40px;
    }
	&:last-child {
		margin-bottom: 0;
	}
`

export const Title = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;
	
	color: var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		margin-bottom: 16px;
		padding-left: 12px;		
		font-size: 18px;
		line-height: 21px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		margin-bottom: 18px;
		font-size: 22px;
		line-height: 26px;		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 19px;
		font-size: 22px;
		line-height: 26px;	
	}	
`

export const List = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		gap: 5px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		gap: 20px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		gap: 20px;
	}	
`
