import React from 'react';
import * as Styled from './styled';
export const EndBanner = () => {
	return (
		<Styled.Box>
			<Styled.EndBanner>
				<Styled.Img />
			</Styled.EndBanner>
		</Styled.Box>
	)
}
