import { IExperience } from "./types";

export const EXPERIENCE_MAIN: IExperience[] = [
	{
		id: 6,
		years: {
			from: '2021',
			to: 'current',
		},
		organization: {
			name: 'Veon',
			city: 'Moscow',
		},
		jobTitle: 'Senior Design Expert',
		responsibilities: [
			'Developed an entire portal for Beeline dedicated to big data',
			`Development of design systems and scripts for the internal product / Improvement of the team's efficiency by 20%`,
			`Redesign of the IOS App, which generates unique entertainment selections / Attracting more than 60% of the audience`,
			`Leading projects in developing interfaces for video, geo, audio – analytics`,
			`Creating Key Visual for Beeline products`,
		],
		link: 'https://www.veon.com/',
	},
	{
		id: 5,
		years: {
			from: '2019',
			to: '2021',
		},
		organization: {
			name: 'Radiology',
			city: 'Moscow',
		},
		jobTitle: 'Lead Designer',
		responsibilities: [
			'Created and launched 6 products',
			'Developed more than 1,000 components',
			'E-commerce for learning courses. Increase in sales by 40%',
			'Created of a Telemed portal. Efficiency of the portal improved on average 50%',
			'Participated in the development of a service to ensure the accuracy of X-ray results for doctors throughout Russia',
			'The accuracy of the results has increased by 40% thanks to the HUB service that I developed',
		],
		link: 'https://tele-med.ai/',
	},
	{
		id: 4,
		years: {
			from: '2018',
			to: '2019',
		},
		organization: {
			name: 'Utkonos',
			city: 'Moscow',
		},
		jobTitle: 'UI/UX designer',
		responsibilities: [
			`Developed a mobile app for food delivery couriers. Increased the speed of couriers' work by an average of 24%. Reduced the load on the operators`,
			`Created a smart email template. Helped speed up the mailing process by ~40%`,
		],
		link: 'https://www.utkonos.ru/',
	},		
	{
		id: 3,
		years: {
			from: '2017',
			to: '2018',
		},
		organization: {
			name: 'SRC',
			city: 'Moscow',
		},
		jobTitle: 'UI/UX designer',
		responsibilities: [
			'Developed 8 interface courses for Astellas. Created 4 training courses for internal employees',
		],
		link: 'https://www.src-multimedia.ru/',
	},	
	{
		id: 2,
		years: {
			from: '2016',
			to: '2017',
		},
		organization: {
			name: 'Factorys',
			city: 'Saint-Petersburg',
		},
		jobTitle: 'UI/Web designer',
		responsibilities: [
			'Maintained UI and Web support of the largest factory in St. Petersburg, and developed product catalogs',
		]
	},
	{
		id: 1,
		years: {
			from: '2013',
			to: '2016',
		},
		organization: {
			name: 'Web studios',
			city: 'Samara',
		},
		jobTitle: 'Junior web designer',
		responsibilities: [
			'In Binomo trading company I created sales increasing communication, animation commercials (about 30), web-banners (about 50)',
			'Developed 20 selling landings and 7 online stores',
		]
	},	
];

export const EXPERIENCE_SIDE: IExperience[] = [
	{
		id: 3,
		years: {
			from: '2021',
			to: '2022',
		},
		organization: {
			name: 'TapYou',
			city: 'Miami',
		},
		jobTitle: 'UI/UX designer',
		responsibilities: [
			`rendered animated emoji ~100 embedded in the app interface`,
			`tested user experience hypotheses, resulting in an 18% increase in user engagement`,
			`Maintained and developed the UI kit library. Created about 400`,
			`Created the IOS client interface of the dating app`,
		],
		link: 'https://tapyou.com/',
	},
	{
		id: 2,
		years: {
			from: '2019',
			to: '2021',
		},
		organization: {
			name: 'Potencial',
			city: 'Moscow',
		},
		jobTitle: 'UI/UX designer',
		responsibilities: [
			'developed an analytical service with complex user flow',
			'Created complex three-axis filtering, wrote system design for the project',
			'Created more than over 200 components',
		],
	},
	{
		id: 1,
		years: {
			from: '2018',
			to: '2019',
		},
		organization: {
			name: 'Enkai',
			city: 'Singapore',
		},
		jobTitle: 'UI/UX designer',
		responsibilities: [
			'Developed a website for a logistics startup',
			'Solved UX and product problems for a service',
			'Created an admin panel interface for vendor and customer interaction',
		],
		link: 'https://enkai.io/',
	},
]

export const EXPERIENCE_ACTIVE: IExperience[] = [
	{
		id: 15,
		years: {
			from: 'october 2022',
			to: '',
		},
		organization: {
			name: 'Medium',
			city: 'UX tricks',
		},
		jobTitle: 'Author',
		responsibilities: [
			`How to determine what needs to be improved in the UX design of your site? Let's look four cool tricks`,
		],
		link: 'https://bootcamp.uxdesign.cc/how-to-determine-what-needs-to-be-improved-in-the-ux-design-of-your-site-5e203b97808',
	},	
	{
		id: 14,
		years: {
			from: 'september 2022',
			to: '',
		},
		organization: {
			name: 'RAUX',
			city: 'International conference',
		},
		jobTitle: 'Speaker',
		responsibilities: [
			`I talk about five simple steps for researching and creating a product.\n
			Here is my video: `,
		],
		link: 'https://raux.design/',
		video: `https://www.youtube.com/watch?v=nugC3K8uLyo`,
	},
	{
		id: 13,
		years: {
			from: 'july 2022',
			to: '',
		},
		organization: {
			name: 'Medium',
			city: 'Looking for ideas',
		},
		jobTitle: 'Author',
		responsibilities: [
			`How to find ideas after usability testing`,
		],
		link: 'https://medium.com/design-pub/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D0%B9%D1%82%D0%B8-%D0%B8%D0%B4%D0%B5%D0%B8-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5-%D1%8E%D0%B7%D0%B0%D0%B1%D0%B8%D0%BB%D0%B8%D1%82%D0%B8-%D1%82%D0%B5%D1%81%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-78ea45886ffd',
	},
	{
		id: 12,
		years: {
			from: 'july 2022',
			to: '',
		},
		organization: {
			name: 'Medium',
			city: 'Usability testing',
		},
		jobTitle: 'Author',
		responsibilities: [
			`At what stage does usability testing take place and what is it for? Instructions for use`,
		],
		link: 'https://medium.com/design-pub/%D0%BD%D0%B0-%D0%BA%D0%B0%D0%BA%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%BF%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%B8%D1%82-%D1%8E%D0%B7%D0%B0%D0%B1%D0%B8%D0%BB%D0%B8%D1%82%D0%B8-%D1%82%D0%B5%D1%81%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8-%D0%B4%D0%BB%D1%8F-%D1%87%D0%B5%D0%B3%D0%BE-%D0%BE%D0%BD%D0%BE-%D0%BD%D1%83%D0%B6%D0%BD%D0%BE-%D0%B8%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D1%8E-e0fe24e0e907',
	},
	{
		id: 11,
		years: {
			from: 'july 2022',
			to: '',
		},
		organization: {
			name: 'Ypstem',
			city: 'Hackaton',
		},
		jobTitle: 'Judge',
		responsibilities: [
			`I evaluated eight projects. I made my selections by identifying key parameters: Content Requirements / Format Requirements / Significance / Accuracy / Originality / Clarity / Engagement /`,
		],
		link: 'https://ypstem.wixsite.com/stemexplorerscontest',
	},
	{
		id: 10,
		years: {
			from: 'june 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Design tips',
		},
		jobTitle: 'Author',
		responsibilities: [
			`Tips for UI/UX Designer. Part 3`,
		],
		link: 'https://habr.com/ru/post/670868/',
	},
	{
		id: 9,
		years: {
			from: 'june 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Design tips',
		},
		jobTitle: 'Author',
		responsibilities: [
			`7 tips to help the interface designer`,
		],
		link: 'https://habr.com/ru/post/670992/',
	},
	{
		id: 8,
		years: {
			from: 'may 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Design tips',
		},
		jobTitle: 'Author',
		responsibilities: [
			`14 Important UI/UX tips for your design`,
		],
		link: 'https://habr.com/ru/post/667188/',
	},
	{
		id: 7,
		years: {
			from: 'may 2022',
			to: '',
		},
		organization: {
			name: 'Ready Set App',
			city: 'Hackaton',
		},
		jobTitle: 'Judge',
		responsibilities: [
			`I judged 22 projects. I evaluated key indicators such as how relevant the problem is to today's events, and how innovative the project is. The quality of design and presentation of the product and teamwork.`,
		],
		link: 'https://readysetapp.devpost.com/',
	},
	{
		id: 6,
		years: {
			from: 'may 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Interviews',
		},
		jobTitle: 'Author',
		responsibilities: [
			'How a UX/UI designer can successfully pass the interview and get a cool offer',
		],
		link: 'https://habr.com/ru/post/664866/',
	},
	{
		id: 5,
		years: {
			from: 'april 2022',
			to: '',
		},
		organization: {
			name: 'Hack Davis',
			city: 'Hackaton',
		},
		jobTitle: 'Judge',
		responsibilities: [
			'I judged 15 projects aimed at improving the environment. My competencies in judging were related to the evaluation of participants on five points: social good, technical complexity, design, creativity and presentation',
		],
		link: 'https://hackdavis2022.devpost.com/?ref_feature=challenge&ref_medium=discover',
	},
	{
		id: 4,
		years: {
			from: 'april 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'The work process',
		},
		jobTitle: 'Author',
		responsibilities: [
			`"The UX/UI Designer's" perfect workflow`,
		],
		link: 'https://habr.com/ru/post/661313/',
	},
	{
		id: 3,
		years: {
			from: 'april 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Design part 2',
		},
		jobTitle: 'Author',
		responsibilities: [
			'Cognitive distortion',
		],
		link: 'https://habr.com/ru/post/658857/',
	},
	{
		id: 2,
		years: {
			from: 'april 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Design part 1',
		},
		jobTitle: 'Author',
		responsibilities: [
			'Gestalt',
		],
		link: 'https://habr.com/ru/post/658851/',
	},
	{
		id: 1,
		years: {
			from: 'march 2022',
			to: '',
		},
		organization: {
			name: 'Habr',
			city: 'Research',
		},
		jobTitle: 'Author',
		responsibilities: [
			'How does a designer conduct UX research alone, without budget or experience',
		],
		link: 'https://habr.com/ru/post/657699/',
	},
]

export const EXPERIENCE = [EXPERIENCE_MAIN, EXPERIENCE_SIDE, EXPERIENCE_ACTIVE];