import React, { FC } from 'react';
import * as Styled from './styled';
import { IHalf } from './types';

export const Half: FC<IHalf> = ({
	$fill,
	$stroke,
	$shadow,
	$height,
	$format,
	$image
}) => {
	return (
		<Styled.Half
			fill={$fill} 
			border={$stroke}
			shadow={$shadow}
		>
			<Styled.HalfImg img={$image} format={$format} heightD={$height[0]} heightT={$height[1]} heightM={$height[2]} />
		</Styled.Half>
	);
}
