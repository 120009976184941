import styled from 'styled-components';
import * as Views from '../../config/views';

export const Grid = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 342px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		width: 760px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 865px;
	}
`

export const Block = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		padding-top: 32px;
		padding-bottom: 32px;
		border-bottom: 1px solid #E1E2E4;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		padding-top: 60px;
		padding-bottom: 60px;
		border-bottom: 1px solid #E1E2E4;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 68px;
		padding-bottom: 68px;
		border-bottom: 1px solid #E1E2E4;
	}
`
