import styled, { css } from 'styled-components';
import * as Views from '../../config/views';

export const ExperienceBox = styled.div`
		width: 100%;
`

export const Experience = styled.div`
	margin: 0 auto;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {     
		padding-top: 54px;
		max-width: 343px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		padding-top: 75px;
		max-width: 640px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 95px;
		max-width: 864px;
	}
`

export const ExperienceTitle = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	letter-spacing: 0.02em;
	color: var(--generalBlack);

	font-weight: 600;
	font-size: 32px;
	line-height: 38px;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		margin-bottom: 55px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-bottom: 33px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-bottom: 39px;
    }
`

export const ChoiceBox = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		width: 330px;
		overflow-y: scroll;
		&::-webkit-scrollbar {
    		display: none;
		}
		margin-bottom: 22px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-bottom: 20px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-bottom: 20px;
    }
`

export const Choice = styled.div`
	display: flex;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		justify-content: space-between;
		width: 456px;
		height: 41px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		justify-content: space-between;
		width: 520px;
		height: 41px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		justify-content: space-between;
		width: 520px;
		height: 41px;
    }
`

export const Item = styled.div<{ active: boolean }>`
	height: 100%;
	${props => props.active && css`
		background-color: var(--generalBlue);
		& > p {
			color: var(--generalWhite);
		}
	`}

	cursor: pointer;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		padding: 10px 8px 10px 8px;
	}
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		padding: 10px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		padding: 10px;
    }
`

export const ItemText = styled.p`
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	font-feature-settings: 'pnum' on, 'lnum' on;	
	color: var(--generalGray);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		
    }
`

export const Description = styled.p`
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.02em;
	font-feature-settings: 'pnum' on, 'lnum' on;
	margin-bottom: 32px;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		max-width: 543px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		max-width: 643px;
    }
`