import React from 'react';
import * as Styled from './styled';
import { Banner } from './Banner/Banner';
import { Experience } from './Experience/Experience';

export const Main = () => {
	return (
		<Styled.Wrapper>
			<h1 className="VisuallyHidden">Alina Kubrak: UI UX Designer</h1>
			<Banner />
			<Styled.BoxLinks>
				<Styled.Item>
					<Styled.Link to="/cases">
						<Styled.Text>Product cases</Styled.Text>
					</Styled.Link>
				</Styled.Item>
				<Styled.Item>
					<Styled.Link to="/art">
						<Styled.Text>Illustrations</Styled.Text>
					</Styled.Link>
				</Styled.Item>
			</Styled.BoxLinks>
			<Experience />
		</Styled.Wrapper>
	)
}
