import React from 'react';
import * as Styled from './styled';
import { EmptyBanner } from '../EmptyBanner/EmptyBanner';
import { Company } from './Company/Company';
import { CASES } from './scheme';

export const Cases = () => {
	return (
		<Styled.Wrapper>
			<h1 className="VisuallyHidden">Alina Kubrak: Product cases</h1>
			<EmptyBanner title="Cases" description="You can read in more detail about my available work and the results of my work and research" />
			<Styled.Cases id="cases">
				{Object.keys(CASES).map((el, i) => {
					return (
						<Company key={`${i}_${el[0]}`} title={el} data={CASES[el]} />
					);
				})}
			</Styled.Cases>
		</Styled.Wrapper>
	)
}
