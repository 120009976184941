import React, { useState, useEffect } from 'react';
import * as Styled from './styled';


export const Header = () => {
	const fullName = 'Alina Kubrak';
	const firstName = 'Alina';
	const menuItems = ['Main', 'Cases', 'Art', 'Contacts'];
	const [showMenu, setShowMenu] = useState(false);

	const handleShowMenu = () => {
		if (showMenu) {
			setShowMenu(prev => !prev);
		}
	}

	useEffect(() => {
		window.addEventListener('resize', handleShowMenu);
		return () => window.removeEventListener('resize', handleShowMenu);
	})

	return (
		<Styled.Wrapper>
			<Styled.Header>
				<Styled.Logo to="/">
					<Styled.LogoImage src="./img/logo.svg" alt="Alina Kubrak logo" />
				</Styled.Logo>
				<Styled.Name className="nameHeaderDesktop">{fullName}</Styled.Name>
				<Styled.Name className="nameHeaderMobile">{firstName}</Styled.Name>
				<Styled.NavBar>
					{menuItems.map((el, i) => (
						<Styled.NavLink key={`${el}_${i}`} to={el === 'Main' ? '/' : `${el.toLowerCase()}`}>{el}</Styled.NavLink>
					))}
				</Styled.NavBar>
				<Styled.BurgerButton active={showMenu} onClick={() => setShowMenu(prev => !prev)}>Menu</Styled.BurgerButton>
			</Styled.Header>
			{showMenu && (
				<Styled.Menu>
					{menuItems.map((el, i) => (
						<Styled.MenuItem key={`${el}_${i}`} onClick={handleShowMenu} to={el === 'Main' ? '/' : el}>{el}</Styled.MenuItem>
					))}
				</Styled.Menu>
			)}
		</Styled.Wrapper>
	);
}
