import React, { FC } from 'react';
import * as Styled from './styled';
import { IPreferences } from './types';

export const Preferences: FC<IPreferences> = ({ type, responsibilities, video }) => {
	return (
		<Styled.Preferences type={type}>
			{responsibilities.map((el, i) => {
				return (
					<Styled.PrefItem key={`${el}_${i}`}>
						<Styled.Text>
							{el}
							{video && <Styled.Video target='_blank' href={video}>watch</Styled.Video>}
						</Styled.Text>		
					</Styled.PrefItem>	
				)
			})}
		</Styled.Preferences>
	);
}

