import { ICase } from "./types";

export const BEELINE: ICase[] = [
	{
		name: 'Big Data Portal',
		typeApp: 'web service',
		year: '2022',
		isNda: false,
		imgD: './img/beeline_desktop.svg',
		imgM: './img/beeline_mobile.svg',
		link: 'bigdata',
		organization: 'beeline',
	},
	{
		name: 'Uneed, entertainment assistant app',
		typeApp: 'app ios',
		year: '2021',
		isNda: true,
		imgD: './img/beeline_desktop.svg',
		imgM: './img/beeline_mobile.svg',
		link: 'uneed',
		organization: 'beeline',
	},
];

export const RADIOLOGY: ICase[] = [
	{
		name: 'Radiology of Moscow',
		typeApp: 'web service',
		year: '2019',
		isNda: false,
		imgD: './img/radiology_desktop.svg',
		imgM: './img/radiology_mobile.svg',
		link: 'radiology',
		organization: 'radiology',
	},
	{
		name: 'Personal cabinet and e-commerce',
		typeApp: 'e-commerce',
		year: '2020',
		isNda: false,
		imgD: './img/radiology_ecom_desktop.svg',
		imgM: './img/radiology_ecom_mobile.svg',
		link: 'radiology-e-commerce',
		organization: 'radiology',
	},
	{
		name: 'HUB AI-based image recognition',
		typeApp: 'health service',
		year: '2019',
		isNda: false,
		imgD: './img/radiology_hub_desktop.svg',
		imgM: './img/radiology_hub_mobile.svg',
		link: 'hub',
		organization: 'radiology',
	},
	{
		name: 'Reporting service for RGP',
		typeApp: 'report service',
		year: '2019',
		isNda: false,
		imgD: './img/radiology_ecom_desktop.svg',
		imgM: './img/radiology_ecom_mobile.svg',
		link: 'radiology-report',
		organization: 'radiology',
	},
];

export const UTKONOS: ICase[] = [
	{
		name: 'App for delivery',
		typeApp: 'app android',
		year: '2018',
		isNda: false,
		imgD: './img/utkonos_desktop.svg',
		imgM: './img/utkonos_mobile.svg',
		link: 'utkonos-delivery',
		organization: 'utkonos',
	},
	{
		name: 'Email builder',
		typeApp: 'communication',
		year: '2018',
		isNda: false,
		imgD: './img/utkonos_desktop.svg',
		imgM: './img/utkonos_mobile.svg',
		link: 'utkonos-email',
		organization: 'utkonos',
	},
];

export const SIDE_PROJECTS: ICase[] = [
	{
		name: 'Data collection and storage service',
		typeApp: 'analytics service',
		year: '2021',
		isNda: false,
		imgD: '',
		imgM: '',
		link: 'analytic-system',
		organization: 'side',
	},
	{
		name: 'Service for learning English',
		typeApp: 'app',
		year: '2021',
		isNda: false,
		imgD: '',
		imgM: '',
		link: 'english-school',
		organization: 'side',
	},
];

export const CASES: any = {
	'Beeline (VEON)': BEELINE,
	'Health Department': RADIOLOGY,
	'Projects for Utkonos': UTKONOS,
	'Side projects': SIDE_PROJECTS,
};