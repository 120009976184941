import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsAna: IBanner = {
	year: '2019',
	jobTitle: 'UI/UX Designer',
	logo: '',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Analysis service',
	description: 'Analytical data collection and storage service',
	backgroundColor: '#75DEFF',
	backgroundImage: './img/analitycs/bannerBgAna.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const ANA_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Participation'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I developed an analytics service for data analysis and post complex metrics as a head designer. I helped the team redesign and developed new design tokens',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#FCFCFC',
			stroke: '',
			format: 'webp',
			height: ['2988px', '2777px', '1206px'],
			images: [['./img/analitycs/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'A system of new components to help designers easily create new layouts. And the development team quickly collected tokens and exported them to their library. Now this will speed up the work and simplify the interaction between designer and developer',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/analitycs/two']],
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/analitycs/three']],
		},
	],	
]
