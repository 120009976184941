import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsHub: IBanner = {
	year: '2019',
	jobTitle: 'Lead Designer',
	logo: './img/hub/bannerLogoRadiology.svg',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'HUB',
	description: 'Medical image recognition service',
	backgroundColor: '#D4E1FA',
	backgroundImage: './img/hub/bannerBgRadiology.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const HUB_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'A key problem'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'During the pandemic, physicians had a very high workload, made mistakes in the research or the research took a long time. To solve this problem, my team and I created a service with Artificial Intelligence, which helped doctors to quickly evaluate the images',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Target audience'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Our target audience was professional physicians, physicians with a small practice, and staff who had just begun their work',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['291px', '254px', '215px'],
			images: [['./img/hub/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Research details'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I conducted research to identify the value proposition, which helped to identify a feature of our service given the needs of our consumer',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['309px', '268px', '128px'],
			images: [['./img/hub/two']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Original concepts'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I worked through several screens to identify pain points and a general idea of how the portal works. I also worked through the user flow for convenience and understanding of the service',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#E5E5E5',
			stroke: '',
			format: 'webp',
			height: ['309px', '261px', '128px'],
			images: [['./img/hub/three']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'User testing. Result'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I conducted usability testing. Ten people participated in the online unmoderated testing. I found that in the first session the interface of the service is not at all clear, the user is afraid to take action without always understanding what happens. On this basis, we have added a page with a description of the service and tips on the interaction sections',
					links: [],
					bolds: []
				},
			],
			link: ''
		},		
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I based the design on the desktop version only, because users load a heavy DICOM file for image recognition in the course of their work. Based on these limitations, the design is only for the desktop version',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/hub/four']],
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'go to the portal'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Thanks to our service we have simplified the work of doctors, and the accuracy of image recognition has increased up to 40%. In the near future, we plan to launch a service for consultative expertise in the field of dentistry. Our service has been published on various information resources and we have received awards from the Russian government',
					links: [],
					bolds: []
				},
				{
					type: 'link',
					text: '',
					links: [['Mos.SM', 'https://mos.sm.news/stolichnyj-servis-analiza-mammogramm-stal-dostupen-medikam-iz-regionov-71409/'], ['Tas', 'https://tass.ru/moskva/10668353'], ['ТВЦ', 'http://www.tvc.ru/news/show/id/202738'], ['Rambler', 'https://news.rambler.ru/moscow_city/45685971-moskovskie-servisy-na-baze-ii-stali-dostupny-vsem-rossiyskim-vracham/'], ['Rbc', 'https://www.rbc.ru/rbcfreenews/61f8f3f29a79475d1334514e'],],
					bolds: []
				},
			],
			link: 'covid.test.npcmr.ru'
		},
	],
]