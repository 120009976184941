import styled from 'styled-components';
import * as Views from '../../config/views';

export const ArtBox = styled.div`
	width: 100%;
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
	}
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const Art = styled.div`
	margin: 0 auto;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		max-width: 341px;
		padding-top: 38px;
		padding-bottom: 39px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		padding-left: 50px;
		padding-right: 50px;
		max-width: 1100px;
		padding-top: 75px;
		padding-bottom: 90px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 1370px;
		padding-top: 84px;
		padding-bottom: 109px;
	}
`

export const List = styled.div`
	display: grid;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		grid-template-columns: 1fr 1fr 1fr;    
		gap: 6px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;    
		gap: 14px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		gap: 20px;
	}
`

export const Item = styled.div`

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		border-radius: 4px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		border-radius: 10px; 
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		border-radius: 10px;
	}
`

export const Photo = styled.img`
	/* height: 100%; */
	display: inline-block;
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   

	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
	}
	// Desktop
	@media (${Views.desktopWidth}) {

	}
`
