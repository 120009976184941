import React, { FC } from 'react';
import * as Styled from './styled';
import { Case } from './Case/Case';
import { ICompany } from './types';

export const Company: FC<ICompany> = ({ title, data }) => {
	return (
		<Styled.CompanyBox>
			<Styled.Title>{title}</Styled.Title>
			<Styled.List>
				{data.map((el, i) => {
					return (
						<Case
							key={`${i}_${el.organization}`}
							name={el.name} 
							typeApp={el.typeApp} 
							year={el.year} 
							isNda={el.isNda} 
							imgD={el.imgD} 
							imgM={el.imgM}
							link={el.link}
							organization={el.organization}
						/>
					);
				})}
			</Styled.List>
		</Styled.CompanyBox>
	)
}
