import styled from 'styled-components';
import * as Views from '../config/views';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	flex-direction: column;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  

	}
	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`

export const Cases = styled.div`
	margin: 0 auto;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		min-width: 376px;
		padding-top: 48px;
		padding-bottom: 85px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		max-width: 640px;
		padding-top: 44px;
		padding-bottom: 180px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 864px;
		padding-top: 47px;
		padding-bottom: 215px;
	}
`