import React, { FC } from 'react';
import * as Styled from './styled';
import { IElement } from './types';
import { Primary } from './Primary/Primary';
import { Usually } from './Usually/Usually';
import { Secondary } from './Secondary/Secondary';


export const Element: FC<IElement> = ({ 
	$block = 'primary', 
	$options = 'oneImg',
	$fill = 'transparent',
	$stroke = 'none',
	$shadow = '',
	$format,
	$height,
	$images,
	$openView,
}) => {
	return (
		<Styled.Element option={$options}>
			{$images.map((images, i) => {
				if ($block === 'primary') {
					return (
						<Primary 
							key={`${$block}_${$options}_${i}`}
							$options={$options} 
							$fill={$fill} 
							$stroke={$stroke} 
							$shadow={$shadow} 
							$format={$format}
							$height={$height}
							$images={images}
							$openView={$openView ? $openView : ['']}
						/>
					);
				} else if ($block === 'secondary') {
					return (
						<Secondary
							key={`${$block}_${$options}_${i}`}
							$shadow={$shadow}
							$format={$format}
							$image={images[0]}
						/>
					);
				} else {
					return (
						<Usually
							key={`${$block}_${$options}_${i}`}
							$format={$format}
							$image={images[0]}
							$height={$height}
						/>
					);
				}
			})}			
		</Styled.Element>
	);
}
