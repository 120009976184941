import styled from 'styled-components';
import * as Views from '../config/views';

export const BannerBox = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	&::after {
		z-index: 1;
		position: absolute;
		content: '';
		background-repeat: no-repeat;
		background-size: cover;
	}
	@media (${Views.mobileWidthStart}) and (max-width: 500px) {     
		margin-top: 64px;
		&::after {
			bottom: 0;
			background-image: url('./img/bannerLineMobileLess.svg');
			width: 100%;
			height: 39px;
		}
	}
	@media (min-width: 500px) and (max-width: 767px) {    
		margin-top: 82px; 
		&::after {
			bottom: 0;
			background-image: url('./img/bannerLineMobile.svg');
			width: 100%;
			height: 39px;
		}
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (max-width: 1250px) { 
		margin-top: 82px; 
		&::after {
			bottom: 0;
			background-image: url('./img/bannerLineTablet.svg');
			width: 100%;
			height: 44px;
		}
	}
	// Tablet
	@media (min-width: 1250px) and (${Views.tabletWidthEnd}) {   
		&::after {
			bottom: 0;
			background-image: url('./img/bannerLine.svg');
			width: 100%;
			height: 44px;
		}
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		&::after {
			bottom: 0;
			background-image: url('./img/bannerLine.svg');
			width: 100%;
			height: 44px;
		}
	}	
`

export const Banner = styled.div`
	position: relative;
	z-index: 1;
	margin: 0 auto;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {     
		max-width: 375px;
		min-height: 441px;
		padding-top: 122px;
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 32px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		max-width: 1100px;
		min-height: 350px;
		padding-top: 112px;
		padding-left: 67px;
		padding-right: 115px;
		padding-bottom: 95px;
		margin-bottom: 60px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 1300px;
		min-height: 410px;
		padding-top: 112px;
		padding-left: 180px;
		padding-right: 115px;
		padding-bottom: 95px;
		margin-bottom: 65px;
	}
`

export const Title = styled.p`
	cursor: default;
	position: relative;
	z-index: 2;
	margin: 0;
	padding: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	letter-spacing: 0.02em;
	font-weight: 700;
	
	color: var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		margin-bottom: 16px;
		font-size: 38px;
		line-height: 46px;	 
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-bottom: 14px;
		font-size: 56px;
		line-height: 60px;		   
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 14px;
		font-size: 56px;
		line-height: 60px;		
	}
`

export const Descr = styled.p`
	cursor: default;
	margin: 0;
	padding: 0;

	
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;	
	color: var(--generalBlack);
	font-feature-settings: 'pnum' on, 'lnum' on;

	
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		font-size: 22px;
		line-height: 26px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		max-width: 630px;
		font-size: 28px;
		line-height: 30px;		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 692px;
		font-size: 28px;
		line-height: 33px;
	}
`
