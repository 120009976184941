import React from 'react';
import { UTKONOS_DATA, bannerPropsUtkonos } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Utkonos = () => {
	return (
		<Template 
			data={UTKONOS_DATA}
			Banner={Banner}
			bannerProps={bannerPropsUtkonos}
		/>
	)
}
