import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsEmail: IBanner = {
	year: '2019',
	jobTitle: 'UI/UX Designer',
	logo: './img/utkoemail/bannerUtkonos.svg',
	logoSize: {
		width: ['40px', '40px', '39px'],
		height: ['44px', '44px', '43px'],
	},
	title: 'Email builder',
	description: 'Designing an easy-to-use email builder',
	backgroundColor: '#FFE690',
	backgroundImage: './img/utkoemail/bannerBgUtkonos.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const EMAIL_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Task'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Update the outdated email service and promotional letters',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: `An important task was to simplify the mailing manager's work. To avoid having to layout every time, we created a set of layouts for different themes`,
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'What mailing topics have been selected'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Together with the owner of the product, we identified 4 main themes in which we will change the communication cards for different email topics',
					links: [],
					bolds: []
				},
				{
					type: 'secondary',
					text: 'We got it: Recipes / Promotions / Promos / Brands / Estimates',
					links: [],
					bolds: [3, 4, 5, 6, 7, 8, 9, 10, 11]
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Process'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'During the design process, it was important to consider all the subtleties of email layout. For example, we had to use only certain system fonts to display correctly on all email services',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'After agreeing on all the design, at the request of the developers I marked the exact distance of the posttups to make our layout look equally beautiful and gave it to the developer',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#DCDCDC',
			stroke: '',
			format: 'webp',
			height: ['424px', '389px', '180px'],
			images: [['./img/utkoemail/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'subscription'
			},
			descriptions: [
				{
					type: 'secondary',
					text: `We've cut the time it takes to launch emails by nearly half`,
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'managers could quickly assemble a communication email without involving a front-end developer',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'I also updated the service communication about 60 template letters',
					links: [],
					bolds: []
				},
			],
			link: 'www.utkonos.ru'
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#E5E5E5',
			stroke: '',
			format: 'webp',
			height: ['491px', '400px', '180px'],
			images: [['./img/utkoemail/two']],
			openView: ['']
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/utkoemail/three']],
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/utkoemail/four']],
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/utkoemail/five']],
		},
	],	
]
