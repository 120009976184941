import styled from 'styled-components';
import * as Views from '../config/views';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
	width: 100%;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		background-color: #F5F5F5;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
    }

	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`
export const Footer = styled.footer`
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	align-items: flex-start;


    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-top: 18px; 
		padding-bottom: 50px;
		padding-left: 22px;
		padding-right: 18px;		
		min-height: 64px;
		max-width: 720px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		padding-top: 28px; 
		padding-left: 54px;
		padding-right: 54px;
		height: 120px;
		max-width: 1100px;
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 28px; 
		height: 138px;
		max-width: 1300px;		
	}	
`

export const Name = styled.p`
	cursor: default;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	letter-spacing: 0.02em;
	color: var(--generalDarkGray);
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		color: var(--generalBlack); 
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;	
		& > br {
			display: block;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		& > br {
			display: none;
		}		
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;	
		& > br {
			display: none;
		}			
	}
`

export const Links = styled.div`
	display: flex;
	margin-left: auto;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		padding-top: 6px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		padding-top: 3px;
    }
    // Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 3px;
    }
`

export const Item = styled.a`
	margin: 0;
	padding: 0;
	text-decoration: none;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;
	color: var(--generalDarkGray);	


    // Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		font-weight: 400;
		font-size: 12px;
		line-height: 14px; 
		color: var(--generalBlack);		   
		margin-right: 17px;
		&:last-child {
			margin-right: 0px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		margin-right: 48px;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		&:last-child {
			margin-right: 0px;
		}
    }
    // Desktop
	@media (${Views.desktopWidth}) {
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		margin-right: 57px;
		&:last-child {
			margin-right: 0px;
		}
    }

`
