import React, { FC, useState } from 'react';
import * as Styled from './styled';
import { ICase } from './types';

export const Case: FC<ICase> = ({ name, typeApp, year, isNda, imgD, imgM, link, organization }) => {
	const [access, setAccess] = useState(isNda);
	const [passwordModal, setPasswordModal] = useState(false);

	if (isNda) {
		return (
			<Styled.CaseNda onClick={() => {

			}} img={organization}>
				<Styled.Options>{typeApp + ' | ' + year}</Styled.Options>
				<Styled.OptionsMobile>{typeApp + ' | ' + year}</Styled.OptionsMobile>
				<Styled.Logo src={imgD} />
				<Styled.LogoMob src={imgM} />
				<Styled.Title>{name}</Styled.Title>
			</Styled.CaseNda>
		);
	}

	return (
		<Styled.Case to={`/${link}`} img={organization}>
			<Styled.Options>{typeApp + ' | ' + year}</Styled.Options>
			<Styled.OptionsMobile>{typeApp + ' | ' + year}</Styled.OptionsMobile>
			<Styled.Logo src={imgD} />
			<Styled.LogoMob src={imgM} />
			<Styled.Title>{name}</Styled.Title>
		</Styled.Case>
	)
}
