import React from 'react';
import { BIG_DATA, bannerPropsBig } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Bigdata = () => {
	return (
		<Template 
			data={BIG_DATA}
			Banner={Banner}
			bannerProps={bannerPropsBig}
		/>
	)
}
