import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsRgp: IBanner = {
	year: '2020',
	jobTitle: 'Lead Designer',
	logo: './img/rgp/bannerLogoRadiology.svg',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Reporter for RGP',
	description: 'Service for certification of the radio hygienic passport',
	backgroundColor: '#D4E1FA',
	backgroundImage: './img/rgp/bannerBgRadiology.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const RGP_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Task'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I worked on this project as a lead Designer. The goal of the project was to develop an improved user experience for medical staff so that they could easily file a radionuclide safety report',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Key issue'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Doctors may have spent too much time uploading the report, and the technical customer support center took a long time to figure out the problem. To reduce the load on our center and make the report submission process more pleasant, we started developing',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Target audience, problems and limitations'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'The main challenge for me was working with doctors. They are not typical computer users, they are very specific in their decisions and logic. I created user maps to better understand how they think. During the testing I identified a common pattern, every interviewee is not familiar with computers and working on the Internet makes them nervous, they often make mistakes and are afraid to do the wrong thing',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['291px', '254px', '215px'],
			images: [['./img/rgp/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Design concepts'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Before I started working on the high-precision layout, I used the Crazy 8 methodology to generate some interesting ideas for designing the main application screen',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#F5F5F4',
			stroke: '',
			format: 'webp',
			height: ['312px', '312px', '215px'],
			images: [['./img/rgp/two']],
			openView: ['']
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Warframes and frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After developing the idea and creating some paper diagrams, I created the initial design for the service. In my designs I used the laws of heuristics and Gestalt laws of cognitive distortion',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#E7E7EC',
			stroke: '',
			format: 'webp',
			height: ['312px', '260px', '340px'],
			images: [['./img/rgp/three']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'User testing results'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'To evaluate the new service, I conducted unmoderated usability testing, with 8 people taking part in the testing. This allowed us to find out that the user makes mistakes in the sequence of steps, gets confused by the download buttons and goes back. Discussions with the user allowed us to understand that, the statuses of the request and the loading indication are poorly read and they cannot distinguish the button and understand the status of the document loading',
					links: [],
					bolds: []
				},
			],
			link: ''
		},		
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I created highly accurate layouts, collected user flow and all this with the desktop version only, because according to our survey, customers did not use the phone to apply',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/rgp/four']],
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#F2F2F2',
			stroke: '',
			format: 'webp',
			height: ['293px', '260px', '497px'],
			images: [['./img/rgp/five']],
			openView: ['']
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'go to the portal'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'We have reduced the load on the support center, the receipt of error-free applications has increased by 30%. The next step will be to create our own document storage service and reduce the steps for filing',
					links: [],
					bolds: [16, 17]
				},
			],
			link: '3doz.tele-med.ai/3-doz-catalog'
		},
	],
]
