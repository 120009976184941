import React from 'react';
import { RADIOLOGY_DATA, bannerPropsRadiology } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Radiology = () => {
	return (
		<Template 
			data={RADIOLOGY_DATA}
			Banner={Banner}
			bannerProps={bannerPropsRadiology}
		/>
	)
}
