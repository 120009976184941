import styled from 'styled-components';
import * as Views from '../config/views';

export const Template = styled.div`
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  

	}
	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`
