import React, { FC } from 'react';
import * as Styled from './styled';
import { IUsually } from './types';

export const Usually: FC<IUsually> = ({ $image, $format, $height }) => {
	return (
		<Styled.Usually>
			<Styled.Img image={$image} format={$format} heightD={$height[0]} heightT={$height[1]} heightM={$height[2]} />
		</Styled.Usually>
	);
}
