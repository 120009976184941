import styled from 'styled-components';
import * as Views from '../../../../../config/views';

export const Preferences = styled.ul<{ type: string }>`
	margin: 0;

	display: flex;
	flex-direction: column;
	list-style-type: ${({ type }) => type === 'activities' ? 'none' : 'disc'};
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		max-width: 281px;
		padding-left: ${({ type }) => type === 'activities' ? `0` : `20px`};
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		max-width: 400px;
		padding-left: ${({ type }) => type === 'activities' ? `0` : `20px`};
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 420px;
		padding-left: ${({ type }) => type === 'activities' ? `0` : `23px`};
	}
`

export const PrefItem = styled.li`
	margin: 0;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		margin-bottom: 0px;
		&:last-child {
			margin-bottom: 0;
		}
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-bottom: 0px;
		&:last-child {
			margin-bottom: 0;
		}
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-bottom: 0px;
		&:last-child {
			margin-bottom: 0;
		}
    }
`

export const Video = styled.a`
	color: var(--generalBlue);
`;

export const Text = styled.p`
	margin: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;

	color: var(--generalDarkGray);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		font-size: 13px;
		line-height: 16px;	  	
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		font-size: 14px;
		line-height: 18px;	
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 14px;
		line-height: 18px;	
	}	
`