import React, { FC } from 'react';
import * as Styled from './styled';
import { IElement } from './types';

export const Element: FC<IElement> = ({ $src, $text }) => {
	return (
		<Styled.Element>
			<Styled.Text>{$text}</Styled.Text>
			<iframe style={{border: `1px solid rgba(0, 0, 0, 0.1)`}} width="100%" height="450" src={$src} allowFullScreen></iframe>
		</Styled.Element>
	);
}