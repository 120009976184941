import React from 'react';
import * as Styled from './styled';
import { EmptyBanner } from '../EmptyBanner/EmptyBanner';
import { Art } from './Art/Art';

export const Illustration = () => {
	return (
		<Styled.Wrapper>
			<h1 className="VisuallyHidden">Alina Shesterikova: Art</h1>
			<EmptyBanner title="Illustrations" description="This work is part of my work on commercial projects, as well as a small portion of the work done on a pro bono basis or to decorate web spaces, articles" />
			<Art />
		</Styled.Wrapper>
	)
}