import styled, { css } from 'styled-components';
import * as Views from '../../../../config/views';

export const Element = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
    }
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const Text = styled.div`
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;


	color: var(--generalBlack);
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 32px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 68px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 68px;
	}
`
