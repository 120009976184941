import React from 'react';
import * as Styled from './styled';

export const Banner = () => {
	return (
		<Styled.BannerBox>
			<Styled.Banner>
				<Styled.Title>Hello! I'm a<br />UI/UX designer</Styled.Title>
				<Styled.Descr>thanks a lot for your visit</Styled.Descr>
				<Styled.ButtonBox>
					<Styled.Connect href='https://t.me/alina_igolkina' target='_blank'>
						<Styled.TextConnect>Contact</Styled.TextConnect>
					</Styled.Connect>
				</Styled.ButtonBox>
				<Styled.Character src="img/char_alina.svg" alt="Character by Alina" />
				<Styled.CharacterM src="img/char_alina_mobile.svg" alt="Character by Alina" />
			</Styled.Banner>
		</Styled.BannerBox>
	)
}
