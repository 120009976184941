import React, { FC } from 'react';
import * as Styled from './styled';
import { IItem } from './types';
import { Preferences } from './Preferences/Preferences';

export const Item: FC<IItem> = ({ id, date, organization, city, jobTitle, responsibilities, link = '', type, videoLink }) => {
	return (
		<Styled.Item>
			<Styled.Pin src="img/pin.svg" alt="pin" />
			<Styled.PinM src="img/pin_mobile.svg" alt="pin"/>
			<Styled.Years>{date}</Styled.Years>
			<Styled.TitleBox>
				{link
					? <Styled.CompanyLink target='_blank' href={link}>{organization + '.'}</Styled.CompanyLink>
					: <Styled.Company>{organization + '.'}</Styled.Company>
				}
				<Styled.Location>{city}</Styled.Location>
			</Styled.TitleBox>
			<Styled.JobTitle>{jobTitle}</Styled.JobTitle>
			<Preferences type={type} responsibilities={responsibilities} video={videoLink} />
		</Styled.Item>
	);
}
