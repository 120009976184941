import React from 'react';
import * as Styled from './styled';
import { ART_IMGS } from '../scheme';

export const Art = () => {
	return (
		<Styled.ArtBox>
			<Styled.Art>
				<Styled.List>
					{ART_IMGS.map((el, i) => {
						return (
							<Styled.Item key={`${el}_${i}`}>
								<Styled.Photo src={el.path} alt={el.alt} />
							</Styled.Item>
						)
					})}
				</Styled.List>
			</Styled.Art>
		</Styled.ArtBox>
	)
}