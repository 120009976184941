import React, { FC } from 'react';
import * as Styled from './styled';
import { ISecondary } from './types';

export const Secondary: FC<ISecondary> = ({ $format, $image, $shadow = '' }) => {
	return (
		<Styled.Secondary>
			<Styled.Box shadow={$shadow}>
				<Styled.Img image={$image} format={$format} />
			</Styled.Box>
		</Styled.Secondary>
	);
}
