import React, { FC, useEffect, useRef } from 'react';
import * as Styled from './styled';
import { IBanner } from './types';

export const Banner: FC<IBanner> = ({
	year, 
	jobTitle,
	logo,
	logoSize,
	title, 
	description, 
	backgroundColor, 
	backgroundImage, 
	backgroundSize 
}) => {
	const scrollRef = useRef(null);

	const scrollToMyRef = (scroll: any) => {
		if (scroll && scroll.current) {
			window.scrollTo(0, 0)
		}
	}

	useEffect(() => {
		scrollToMyRef(scrollRef);
	}, []);

	return (
		<Styled.Box ref={scrollRef} color={backgroundColor}>
			<Styled.Banner>
				<Styled.Bread to='/cases'>to cases</Styled.Bread>
				<Styled.Job>{`${year}   |   ${jobTitle}`}</Styled.Job>
				<Styled.LinkLogo 
					href='#'
					heightD={logoSize.height[0]} 
					heightT={logoSize.height[1]} 
					heightM={logoSize.height[2]} 
					widthD={logoSize.width[0]} 
					widthT={logoSize.width[1]} 
					widthM={logoSize.width[2]} 
				>
					{logo ? (
						<Styled.Logo
							src={logo || ''}
							heightD={logoSize.height[0]} 
							heightT={logoSize.height[1]} 
							heightM={logoSize.height[2]} 
							widthD={logoSize.width[0]} 
							widthT={logoSize.width[1]} 
							widthM={logoSize.width[2]} 
						/>
					) : (
						<Styled.LogoEmpty
							heightD={logoSize.height[0]} 
							heightT={logoSize.height[1]} 
							heightM={logoSize.height[2]} 
							widthD={logoSize.width[0]} 
							widthT={logoSize.width[1]} 
							widthM={logoSize.width[2]} 
						/>
					)}

				</Styled.LinkLogo>
				<Styled.Title>{title}</Styled.Title>
				<Styled.Description>{description}</Styled.Description>
				<Styled.Bg 
					img={backgroundImage || ''}
					heightD={backgroundSize.height[0]} 
					heightT={backgroundSize.height[1]} 
					heightM={backgroundSize.height[2]} 
					widthD={backgroundSize.width[0]} 
					widthT={backgroundSize.width[1]} 
					widthM={backgroundSize.width[2]} 
				/>
			</Styled.Banner>
		</Styled.Box>
	)
}
