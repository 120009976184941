import React, { FC } from 'react';
import * as Styled from './styled';
import { EXPERIENCE } from './scheme';
import { Item } from './Item/Item';
import { IList } from './types';

export const List: FC<IList> = ({ type }) => {
	const typeIndex = type === 'main' ? 0 : type === 'side' ? 1 : 2;
	return (
		<Styled.List>
			{EXPERIENCE[typeIndex].map((el, i) => {
				const date = el.years.from + (el.years.to ? ` - ${el.years.to}` : ``);
				return (
						<Item
							key={el.id}
							id={el.id}
							date={date}
							organization={el.organization.name}
							city={el.organization.city}
							jobTitle={el.jobTitle}
							responsibilities={el.responsibilities}
							link={el.link ? el.link : ''}
							type={type}
							videoLink={el.video ? el.video : ''}
						/>
				)
			})}
		</Styled.List>
		
	);
}