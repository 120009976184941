import styled, { css } from 'styled-components';
import * as Views from '../../../../config/views';

export const Element = styled.div`
	background-color: #fafafa;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;


    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		flex-direction: column;
		align-items: center;

		padding: 26px;
		&:not(:last-child) {
			margin-bottom: 26px;
		} 
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		padding: 30px;
		&:not(:last-child) {
			margin-bottom: 32px;
		}  
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		padding: 33px 47px;
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
`

export const Item = styled.p`
	margin: 0;
	padding: 0;
	width: 100%;

	font-family: 'Source Serif Pro';
	font-style: italic;
	font-weight: 400;

	color: var(--generalBlack);	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 255px;
		font-size: 13px;
		line-height: 16px;
		&:not(:last-child) {
			margin-bottom: 18px;
		} 		
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		width: 218px;
		font-size: 18px;
		line-height: 26px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 215px;
		font-size: 18px;
		line-height: 26px;

		&:not(:last-child) {
			/* margin-right: 62px; */
		}
	}
`

