import { createGlobalStyle } from 'styled-components';

export const Fonts = createGlobalStyle`
	/* raleway-100 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 100;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-200 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 200;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-300 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 300;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 400;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 500;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 600;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 700;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-800 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 800;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-900 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 900;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-100italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 100;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-300italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 300;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-200italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 200;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 400;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-500italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 500;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-600italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 600;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-700italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 700;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-800italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 800;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* raleway-900italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Raleway';
		font-style: italic;
		font-weight: 900;
		src: url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/raleway-v26-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.svg#Raleway') format('svg'); /* Legacy iOS */
		font-display: swap;
	}

	/* source-serif-pro-200 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 200;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-200italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 200;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-300 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 300;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-300italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 300;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-regular - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 400;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 400;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-600italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 600;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-600 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 600;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-700 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 700;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-700italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 700;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-900 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: normal;
		font-weight: 900;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;
	}
	/* source-serif-pro-900italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
	@font-face {
		font-family: 'Source Serif Pro';
		font-style: italic;
		font-weight: 900;
		src: url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.eot'); /* IE9 Compat Modes */
		src: local(''),
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('../fonts/source-serif-pro-v11-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-900italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
		font-display: swap;			
	}
`;