import styled, { css } from 'styled-components';
import * as Views from '../../../../../config/views';

export const Usually = styled.div`
	width: 100%;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
    }
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const Img = styled.div<{ image: string, format: string, heightD: string, heightT: string, heightM: string }>`
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		min-height: ${({ heightM }) => heightM};
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_m.${props.format}")`}; 
		`}	
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		min-height: ${({ heightT }) => heightT};
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_t.${props.format}")`}; 
		`}	
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		min-height: ${({ heightD }) => heightD};
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_d.${props.format}")`}; 
		`}	
	}
`
