import { url } from 'inspector';
import styled, { css } from 'styled-components';
import * as Views from '../../../../../config/views';

export const Primary = styled.div<{ option: string }>`
	width: 100%;
	&:not(:last-child) {
		margin-bottom: 20px;
	}

	${({ option }) => option === 'halfImg' && css`
		display: flex;
		flex-wrap: wrap;
	`}
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
    }
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const One = styled.div<{ fill: string, border: string, shadow: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	border: 1px solid var(--generalBlack);
	background-color: transparent;
	box-shadow: 0px 20px 25px rgba(177, 181, 188, 0.15);

	${({ fill }) => fill && css`
		background-color: ${fill};
	`}
	${({ border }) => border && css`
		border-color: ${border};
	`}
	${({ shadow }) => shadow && css`
		box-shadow: ${shadow};
	`}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		padding: 8px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		padding: 18px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		padding: 20px;
	}
`

export const OneImg = styled.div<{ img: string, format: string, heightM: string, heightT: string, heightD: string, cursor: string }>`
	background-repeat: no-repeat;
	background-size: contain;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 327px;
		min-height: ${({ heightM }) => heightM};
		${props => props.img && props.format && css`
		background-image: ${`url("${props.img}_m.${props.format}")`}; 
		`}
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 724px;
		min-height: ${({ heightT }) => heightT};
		${props => props.img && props.format && css`
		background-image: ${`url("${props.img}_t.${props.format}")`}; 
		`}
	}
	// Desktop
	@media (${Views.desktopWidth}) {
			width: 824px;
			min-height: ${({ heightD }) => heightD};
			${props => props.img && props.format && css`
				background-image: ${`url("${props.img}_d.${props.format}")`}; 
			`}
			${({ cursor }) => cursor === 'true' && css`
				cursor: pointer;
			`}
	}
`

export const ModalBox = styled.div`
	position: fixed;
	overflow-y: scroll;
	z-index: 100;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 1px;
		height: 1px;
		display: none;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 1px;
		height: 1px;
		display: none;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		top: 0;
		width: 100vw;
		height: 100vh;
		padding-top: 35px;
		padding-bottom: 35px;
		background-color: #DEDEDE;
	}
`

export const Modal = styled.div`
	background-color: #fff;
	overflow-y: scroll;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		position: relative;
		margin: 0 auto;
		border-radius: 10px;
		border: 1px solid var(--generalBlack);
		width: 964px;
		padding: 31px 104px 45px;
	}
`

export const Close = styled.div`
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  

	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {

	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 35px;
		height: 35px;
		position: absolute;
		top: 21px;
		right: 26px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url('./img/closeModalIcon.svg');
		cursor: pointer;
	}
`

export const CloseBar = styled.div`
	position: fixed;
	width: 964px;
	left: 50%;
	top: 35px;
	margin-left: -482px;
	background-color: green;
`

export const ModalImg = styled.div<{ img: string, height: string, format: string }>`
	
	width: 756px;
	background-repeat: no-repeat;
	background-size: contain;
	${({ height }) => height && css`
		height: ${height};
	`}
	${({ img, format }) => img && css`
		background-image: url(${img}_d.${format});
	`}
`

export const Two = styled.div`
	display: flex;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		flex-direction: column; 
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
    }
	// Desktop
	@media (${Views.desktopWidth}) {

	}
`

export const TwoLeft = styled.div<{ fill: string, border: string, shadow: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid var(--generalBlack);
	background-color: transparent;
	box-shadow: none;
		
	${({ fill }) => fill && css`
		background-color: ${fill};
	`}
	${({ border }) => border && css`
		border-color: ${border};
	`}
	${({ shadow }) => shadow && css`
		box-shadow: ${shadow};
	`}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		width: 100%;
		padding: 8px;
		margin-bottom: 20px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 282px;
		padding: 18px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 321px;
		padding: 20px;
	}
`

export const TwoLeftImg = styled.div<{ img: string, format: string, heightD: string, heightT: string, heightM: string }>`
	width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		min-height: ${({ heightM }) => heightM};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_m.${props.format}")`}; 
		`}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		min-height: ${({ heightT }) => heightT};
		${props => props.img && props.format && css`
		background-image: ${`url("${props.img}_t.${props.format}")`}; 
		`}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		min-height: ${({ heightD }) => heightD};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_d.${props.format}")`}; 
		`}
	}
`

export const TwoRight = styled.div<{ fill: string, border: string, shadow: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid var(--generalBlack);
	background-color: transparent;
	box-shadow: none;

	${({ fill }) => fill && css`
		background-color: ${fill};
	`}
	${({ border }) => border && css`
		border-color: ${border};
	`}
	${({ shadow }) => shadow && css`
		box-shadow: ${shadow};
	`}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		width: 100%;
		padding: 8px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 460px;
		padding: 18px;
		margin-left: auto;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 521px;
		padding: 20px;
		margin-left: auto;
	}
`

export const TwoRightImg = styled.div<{ img: string, format: string, heightD: string, heightT: string, heightM: string }>`
	width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		min-height: ${({ heightM }) => heightM};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_m.${props.format}")`}; 
		`}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		min-height: ${({ heightT }) => heightT};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_t.${props.format}")`}; 
		`}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		min-height: ${({ heightD }) => heightD};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_d.${props.format}")`}; 
		`}
	}
`

export const Half = styled.div<{ fill: string, border: string, shadow: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid var(--generalBlack);
	background-color: transparent;
	box-shadow: 0px 20px 25px rgba(177, 181, 188, 0.15);
	
	${({ fill }) => fill && css`
	background-color: ${fill};
	`}
	${({ border }) => border && css`
	border-color: ${border};
	`}
	${({ shadow }) => shadow && css`
	box-shadow: ${shadow};
	`}
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 100%;
		padding: 8px;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 371px;
		min-height: 266px;
		padding: 18px;
		&:nth-child(2n) {
			margin-left: auto;
		}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		padding: 20px;
		width: 422px;
		&:nth-child(2n) {
			margin-left: auto;
		}
	}
`

export const HalfImg = styled.div<{ img: string, format: string, heightD: string, heightT: string, heightM: string }>`
	background-repeat: no-repeat;
	background-size: contain;
	
	width: 100%;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		min-height: ${({ heightM }) => heightM};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_m.${props.format}")`}; 
		`}
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		min-height: ${({ heightT }) => heightT};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_t.${props.format}")`}; 
		`}
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		min-height: ${({ heightD }) => heightD};
		${props => props.img && props.format && css`
			background-image: ${`url("${props.img}_d.${props.format}")`}; 
		`}
	}
`