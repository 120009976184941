import styled, { css } from 'styled-components';
import * as Views from '../../../../config/views';

export const Element = styled.div<{ option: string }>`
	width: 100%;
	display: flex;
	flex-direction: column;

	${({ option }) => option === 'halfImg' && css`
			flex-direction: row;
	`}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		&:not(:last-child) {
			margin-bottom: 26px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
`
