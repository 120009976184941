import styled from 'styled-components';


export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	flex-direction: column;
`

