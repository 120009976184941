import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsUtkonos: IBanner = {
	year: '2018',
	jobTitle: 'UI/UX Designer',
	logo: './img/utkonos/bannerUtkonos.svg',
	logoSize: {
		width: ['40px', '40px', '39px'],
		height: ['44px', '44px', '43px'],
	},
	title: 'Utkonos app',
	description: 'Automating the application process for drivers',
	backgroundColor: '#FFE690',
	backgroundImage: './img/utkonos/bannerBgUtkonos.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const UTKONOS_DATA: (Data.IElementDataText | Data.IElementDataImage | Data.IElementDataFrame)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Why'
			},
			subtitle: {
				type: 'primary',
				text: `it made the courier's work more difficult and burdened the call center tech support + the process took a long time`
			},
			descriptions: [
				{
					type: 'primary',
					text: `I worked with a product designer to develop an app for grocery delivery couriers. We solved the problem of an awkward and backward interface, and performed a redesign for an app that had many bugs and imperfections that were affecting the company's earnings`,
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'Couriers and drivers most often used printed product sheets, and logged into the app to make a report or payment',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Investigation Surveillance'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I made a portrait of the client, collected qualitative and quantitative data based on the metrics we already have',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'For further work I chose several types of research',
					links: [],
					bolds: []
				},
				{
					type: 'secondary',
					text: 'Collected data through an observational study - went on a trip with a courier and observed how he uses the application and how often he interacts with the interface, what problems he experiences',
					links: [],
					bolds: []
				},
				{
					type: 'secondary',
					text: 'Conducted a survey of the interview - found out how our user thinks, what important problems he observes in the application.All the comments I posted on the board and chose the most important points. Which I worked with next',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'What problems have been identified'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Most of the interviewed drivers pointed out that:',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'secondary',
			central: ['–  There is no indication of which order is in the queue and the customer is confused', ` – It's hard to switch from one application to another to build a route`, `– It constantly hangs up, it's impossible to make quick payments`]
		},
	],	


	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Warframes'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After collecting the warframes, I received feedback from our users. After making sure that the logic of our warframe was clear, I compiled a user flow and continued designing a high-precision layout',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['372px', '329px', '273px'],
			images: [['./img/utkonos/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Finished prototypes'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After putting together our design, we did a usability study. Together with the courier, we drove around and asked them to perform a few actions in the app',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'During the study, we found that our application requires improvement on the dark theme, because drivers work in the dark, and the light screens are very bright',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'We left this point for the next iteration, and there was another interesting project waiting for me',
					links: [],
					bolds: []
				},
			],
			link: ''
		},		
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#E9ECF8',
			stroke: '',
			format: 'webp',
			height: ['266px', '266px', '193px'],
			images: [['./img/utkonos/two']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'As a result, our couriers stopped being late by an average of 24%, and we also reduced operator service costs',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'The load on the technical support operators reduced, because in previous versions of the duckmobile all worked very slowly and the courier often called the operator to manually add or correct some data about the order',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'frame',
			src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FY0tDTRiZR9DL8TeZkMMbIr%2FUtkonos-prototype%3Fpage-id%3D1%253A8498%26node-id%3D1%253A16203%26viewport%3D803%252C750%252C0.06%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A13186',
			text: 'You can view its prototype in Figma'
		}
	],
]
