import styled from 'styled-components';
import * as Views from '../../config/views';

export const BannerBox = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		margin-top: 64px;   
		border-bottom: 2px solid var(--generalBlack);
		margin-bottom: 28px;
	}
`

export const Banner = styled.div`
	position: relative;
	z-index: 1;
	margin: 0 auto;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {     
		max-width: 375px;
		padding-top: 39px;
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 32px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		max-width: 1100px;
		padding-top: 112px;
		padding-left: 67px;
		padding-right: 115px;
		padding-bottom: 95px;
		margin-bottom: 60px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 1300px;
		padding-top: 112px;
		padding-left: 67px;
		padding-right: 115px;
		padding-bottom: 95px;
		margin-bottom: 65px;
	}
`

export const Title = styled.p`
	cursor: default;
	position: relative;
	z-index: 2;
	margin: 0;
	padding: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	letter-spacing: 0.02em;
	font-weight: 700;
	
	color: var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		margin-bottom: 16px;
		font-size: 38px;
		line-height: 46px;	 
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-bottom: 20px;
		font-size: 56px;
		line-height: 60px;		   
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 22px;
		font-size: 56px;
		line-height: 60px;		
	}
`

export const Descr = styled.p`
	cursor: default;
	margin: 0;
	padding: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	color: var(--generalBlack);
	font-weight: 400;
	letter-spacing: 0.02em;	
	
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		margin-bottom: 362px;
		font-size: 22px;
		line-height: 28px;
	}
	@media (min-width: 280px) and (max-width: 380px) {    
		margin-bottom: 340px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		margin-bottom: 60px;
		font-size: 28px;
		line-height: 30px;		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 66px;
		font-size: 28px;
		line-height: 30px;	
	}
`

export const ButtonBox = styled.div`
	display: flex;
	position: relative;
	z-index: 100;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		margin: 0 auto;
		width: 235px;
		height: 63px;
	}	
`

export const Connect = styled.a`
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--generalWhite);
	
	border: 1px solid var(--generalBlack);
	transition: 0.3s;
	
	&:hover {
		transform: translateY(-5px);
	}

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		width: 235px;
		height: 63px;
		
		border-radius: 8px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		width: 196px;
		height: 63px;
		
		border-radius: 12px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 196px;
		height: 63px;
		
		border-radius: 12px;
		border: 1px solid var(--generalBlack);
	}
`

export const TextConnect = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.085em;
	text-transform: uppercase;
	
	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		font-size: 18px;
		line-height: 21px;	   
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		font-size: 18px;
		line-height: 21px;		
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		font-size: 18px;
		line-height: 21px;		
    }	
`

export const Character = styled.img`
	position: absolute;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		display: none;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		top: 97px;
		right: 25px;
    }
	@media (min-width: 850px) and (max-width: 879px) {
		right: 0px;

	}
	@media (min-width: 768px) and (max-width: 849px) {
		display: none;
	}

    // Desktop
    @media (${Views.desktopWidth}) {
		top: 105px;
		right: 0px;
    }
`

export const CharacterM = styled.img`
	position: absolute;
	z-index: 0;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		display: none;
		top: 185px;
		display: block;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		top: 97px;
		right: 25px;
		display: none;
    }
	@media (min-width: 850px) and (max-width: 879px) {
		right: 0px;
		display: none;
	}
	@media (min-width: 768px) and (max-width: 849px) {
		display: none;
		right: 0;
		display: block;
	}

    // Desktop
    @media (${Views.desktopWidth}) {
		top: 105px;
		right: 0px;
		display: none;
    }
`
