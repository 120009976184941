import React from 'react';
import { RGP_DATA, bannerPropsRgp } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Rgp = () => {
	return (
		<Template 
			data={RGP_DATA}
			Banner={Banner}
			bannerProps={bannerPropsRgp}
		/>
	)
}
