import React from 'react';
import { ENGLISH_DATA, bannerPropsEnglish } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Lenglish = () => {
	return (
		<Template 
			data={ENGLISH_DATA}
			Banner={Banner}
			bannerProps={bannerPropsEnglish}
		/>
	)
}
