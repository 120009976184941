import React from 'react';
import { EMAIL_DATA, bannerPropsEmail } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const UtkoEmail = () => {
	return (
		<Template 
			data={EMAIL_DATA}
			Banner={Banner}
			bannerProps={bannerPropsEmail}
		/>
	)
}
