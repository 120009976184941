import React, { FC } from 'react';
import * as Styled from './styled';
import { ITitle } from './types';

export const Title: FC<ITitle> = ({ $type, $text, $isSub }) => {
	return (
		<Styled.Title type={$type} isSub={$isSub}>
			{$text}
		</Styled.Title>
	);
}
