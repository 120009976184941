import styled, { css } from 'styled-components';
import * as Views from '../../../../config/views';

export const Element = styled.div`
	display: flex;
	width: 100%;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		flex-direction: column;
		&:not(:last-child) {
			margin-bottom: 26px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		justify-content: space-between;
		&:not(:last-child) {
			margin-bottom: 32px;
		}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		justify-content: space-between;
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
`

export const LeftBlock = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		margin-bottom: 26px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		width: 360px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 420px;
	}
`

export const RightBlock = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		width: 360px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 420px;
	}
`

export const Title = styled.h2<{ type: string, isSub: boolean }>`
	margin: 0;
	padding: 0;
	color: var(--generalBlack);

	${props => props.type === 'primary' && css`
		font-family: 'Raleway';
		font-style: normal;
	`}
	${props => props.type === 'secondary' && css`
		font-family: 'Source Serif Pro';
		font-style: normal;
	`}
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		${props => props.isSub && css`
			margin-bottom: 10px;
		`}
		${props => props.type === 'primary' && css`
			font-weight: 700;
			font-size: 32px;
			line-height: 38px;
			letter-spacing: 0.02em;
			font-feature-settings: 'pnum' on, 'lnum' on;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 600;
			font-size: 24px;
			line-height: 20px;
		`}
		
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		${props => props.type === 'primary' && css`
			font-weight: 700;
			font-size: 38px;
			line-height: 48px;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 600;
			font-size: 32px;
			line-height: 40px;
		`}	
		margin-bottom: 10px;	
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		${props => props.type === 'primary' && css`
			font-weight: 700;
			font-size: 38px;
			line-height: 48px;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 600;
			font-size: 32px;
			line-height: 40px;
		`}	
		margin-bottom: 10px;	
	}
`

export const Subtitle = styled.h3<{ type: string }>`
	margin: 0;
	padding: 0;
	color: var(--generalBlack);

	${props => props.type === 'primary' && css`
		font-family: 'Raleway';
		font-style: normal;
	`}
	${props => props.type === 'secondary' && css`
		font-family: 'Source Serif Pro';
		font-style: normal;
	`}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		${props => props.type === 'primary' && css`
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
		`}
	}
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		${props => props.type === 'primary' && css`
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 400;
			font-size: 22px;
			line-height: 22px;
		`}	
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 380px;
		${props => props.type === 'primary' && css`
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
		`}
		${props => props.type === 'secondary' && css`
			font-weight: 400;
			font-size: 22px;
			line-height: 22px;
		`}	
	}
`

export const SubtitleLink = styled.a`
	display: block;
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;
	text-decoration-line: underline;

	color: var(--generalBlue);
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		font-weight: 500;
		font-size: 20px;
		line-height: 27px;
	}	
`

export const Description = styled.div`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		&:not(:last-child) {
			margin-bottom: 14px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		&:not(:last-child) {
			margin-bottom: 14px;
		}
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		&:not(:last-child) {
			margin-bottom: 14px;
		}
	}
`

export const DescriptionLinks = styled.div`
	display: flex;
	flex-wrap: wrap;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
    }
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const Text = styled.p<{ type: string }>`
	margin: 0;
	padding: 0;
	margin-right: 1px;
	display: inline;
	font-family: 'Source Serif Pro';
	font-style: normal;
	color: var(--generalBlack);
	font-weight: 400;
	
	${props => props.type === 'primary' && css`
		font-size: 16px;
		line-height: 20px;
		letter-spacing: -0.02px; 
	`}
		${props => props.type === 'secondary' && css`
		font-size: 22px;
		line-height: 20px;
	`}	

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
    }
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

export const Link = styled.a`
	position: relative;
	margin: 0;
	padding: 0;
	display: block;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	text-decoration-line: underline;
	color: var(--generalBlue);

	&:not(:last-child) {
		margin-right: 13px;
		&::after {
			position: absolute;
			content: '';

			right: -6px;
			top: 1px;
			height: 16px;
			width: 1px;
			background-color: var(--generalBlack);

		}
	}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		font-size: 16px;
		line-height: 20px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		font-size: 16px;
		line-height: 20px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 16px;
		line-height: 20px;
	}
`

export const Bold = styled.span`
	font-weight: 700;
`
