import React, { FC } from 'react';
import { text } from 'stream/consumers';
import * as Styled from './styled';
import { IDescription } from './types';

export const Description: FC<IDescription> = ({ $type, $text = '', $links = [], $boldIndex = [] }) => {
	if ($type === 'link')  {
		return (
			<Styled.DescriptionLinks>
				{$links.map((link, i) => {
					return (
						<Styled.Link target="_blank" key={`${link}_${i}`} href={link[1]}>{link[0]}</Styled.Link>
						);
					})}
			</Styled.DescriptionLinks>
		);
	}
	const texts = $text.split(' ');
	return (
		<Styled.Description>
			<Styled.Text type={$type}>
				{texts.map((text, i) => {
					if ($boldIndex.includes(i)) {
						return <Styled.Bold key={`${$type}_${i}`}>{text + ' '}</Styled.Bold>
					}
					return (text + ' ');
				})}
			</Styled.Text>
		</Styled.Description>
	);
}