import React, { FC } from 'react';
import * as Styled from './styled';
import { ISubtitle } from './types';

export const Subtitle: FC<ISubtitle> = ({ $type, $text, $link = '' }) => {
	if ($type === 'link') {
		return <Styled.SubtitleLink target='_blank' href={`http://${$link}`}>{$text}</Styled.SubtitleLink>
	}
	return (
		<Styled.Subtitle type={$type}>
			{$text}
		</Styled.Subtitle>
	);
}