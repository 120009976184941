import styled from 'styled-components';
import * as Views from '../config/views';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	flex-direction: column;
	&::-webkit-scrollbar {
		-webkit-overflow-scrolling: touch;
		display: none;
		height: 0;
		width: 0;
	}
`

export const BoxLinks = styled.div`
	margin: 0 auto;
	display: flex;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		min-width: 330px; 
		flex-direction: column;
		margin-top: 12px;
		padding-bottom: 14px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		min-width: 680px;
		height: 243px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		min-width: 861px;
		height: 243px;
    }		
`

export const Item = styled.div`
	position: relative;

	&::after {
		position: absolute;
		content: '';		
		background-repeat: no-repeat;
		background-size: contain;
	}
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		height: 176px;
		border-radius: 3.3px; 
		flex-direction: column;
		&:first-child {
			margin-bottom: 41px;
		}
		&::after {
			width: 350px;
			height: 203px;
			background-image: url('./img/linkItemBgMobile.svg');
			z-index: -1;
			top: -14px;
			left: -12px;
		}
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 

		border-radius: 4px;
		width: 50%;
		&:first-child {
			margin-right: 15px;
		}
		&::after {
			width: 328px;
			height: 238px;
			background-image: url('./img/linkItemBgTablet.svg');
			z-index: -1;
			top: -1px;
			left: 0px;
		}
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		border-radius: 4px;
		width: 50%;
		&:first-child {
			margin-right: 19px;
		}
		&::after {
			opacity: 1;
			z-index: 0;
			top: -2px;
			left: -2px;
			transition: .5s ease-out;
			width: 421px;
			height: 245px;
			background-image: url('./img/linkItemBgDesktop.svg');
		}
		&:hover {
			border-color: transparent;
			&::after {
				opacity: 1;
				z-index: -1;
				top: -2px;
				left: -2px;
				background-image: url('./img/linkItemBgDesktopHover.svg');
				width: 421px;
				height: 245px;
			}
		}
    }
`

export const Link = styled(RouterLink)`
	text-decoration: none;
	display: block;
	width: 100%;
	height: 100%;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		padding-top: 51px;
		padding-left: 33px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		padding-top: 65px;
		padding-left: 43px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		padding-top: 83px;
		padding-left: 58px;
    }		
`

export const Text = styled.p`
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 33px;
	letter-spacing: 0.02em;
	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		font-size: 27px;
		line-height: 32px;
		max-width: 212px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		max-width: 240px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		max-width: 257px;
    }	
`