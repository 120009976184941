import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Views from '../../../config/views';

export const Case = styled(Link)<{ img: string }>`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	text-decoration: none;
	color: var(--generalBlack);
	font-family: 'Raleway';
	font-style: normal;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;	
	letter-spacing: 0.02em;
	border-radius: 4px;

	transition: 0.3s;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		padding-top: 50px;
		padding-left: 22px;
		padding-right: 12px;
		height: 201px;
		width: 185px;
		background-image: ${props => `url(./img/${props.img}_caseBgMobile.svg)`};
		background-repeat: no-repeat;
		background-size: cover;	
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		padding-top: 51px;
		padding-left: 35px;
		padding-right: 26px;
		height: 333px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: ${props => `url(./img/${props.img}_caseBgTablet.svg)`};
    }
	
    // Desktop
    @media (${Views.desktopWidth}) {
		border: 2px solid var(--generalBlack);
		border-bottom: 4px solid var(--generalBlack);
		padding-top: 55px;
		padding-left: 55px;
		padding-right: 26px;
		height: 333px;
		&:hover {
			&::after {
				content: '';
				position: absolute;

				width: 431px;
				height: 341px;
				background-repeat: no-repeat;
				background-size: contain;
				top: -9px;
				left: -5px;
				background-image: ${props => `url(./img/${props.img}_caseBg.svg)`};
			}
		}
    }	
`

export const CaseNda = styled.div<{ img: string }>`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	text-decoration: none;
	color: var(--generalBlack);
	font-family: 'Raleway';
	font-style: normal;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;	
	letter-spacing: 0.02em;
	border-radius: 4px;
	cursor: not-allowed;
	transition: 0.3s;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		padding-top: 50px;
		padding-left: 22px;
		padding-right: 12px;
		height: 201px;
		width: 185px;
		background-image: ${props => `url(./img/${props.img}_caseBgMobile.svg)`};
		background-repeat: no-repeat;
		background-size: cover;	
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		padding-top: 51px;
		padding-left: 35px;
		padding-right: 26px;
		height: 333px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: ${props => `url(./img/${props.img}_caseBgTablet.svg)`};
    }
	
    // Desktop
    @media (${Views.desktopWidth}) {
		border: 2px solid var(--generalBlack);
		border-bottom: 4px solid var(--generalBlack);
		padding-top: 55px;
		padding-left: 55px;
		padding-right: 26px;
		height: 333px;
		&:hover {
			&::after {
				content: '';
				position: absolute;

				width: 431px;
				height: 341px;
				background-repeat: no-repeat;
				background-size: contain;
				top: -9px;
				left: -5px;
				background-image: ${props => `url(./img/${props.img}_caseBg.svg)`};
			}
		}
    }	
`

export const Options = styled.p`
	position: relative;
	z-index: 3;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;

	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {       
		display: none;
		font-size: 12px;
		line-height: 14px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		display: none;
		font-size: 16px;
		line-height: 19px;	
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		display: block;
		font-size: 16px;
		line-height: 19px;	
    }
`

export const OptionsMobile = styled.p`
	position: relative;
	z-index: 3;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;

	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {       
		display: block;
		font-size: 12px;
		line-height: 14px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		display: block;
		font-size: 16px;
		line-height: 19px;	
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		display: none;
		font-size: 16px;
		line-height: 19px;	
    }
`

export const Logo = styled.img`
	position: absolute;
	z-index: 3;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		display: none; 
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		display: block;   
		right: 26px;
		top: 30px;
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		display: block;
		right: 46px;
		top: 45px;
    }
`

export const LogoMob = styled.img`
	position: absolute;
	z-index: 3;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		display: block; 
		right: 16px;
		top: 16px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		display: none;   
		right: 26px;
		top: 30px;
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		display: none;
		right: 26px;
		top: 30px;
    }
`

export const Title = styled.p`
	position: relative;
	z-index: 3;
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.02em;
	
	color: var(--generalBlack);
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		margin-top: 10px;
		font-size: 20px;
		line-height: 23px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		margin-top: 60px;
		margin-bottom: 20px;
		font-size: 28px;
		line-height: 33px;
    }
	
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-top: 54px;
		margin-bottom: 27px;
		font-size: 28px;
		line-height: 33px;	
    }
`

export const Bg = styled.img`
	position: absolute;
	z-index: 1;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		width: 74%;
		right: 0px;
		bottom: 0px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		right: 0px;
		bottom: 0px;
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		right: 0px;
		bottom: 0px;
    }
`

export const BgTablet = styled.img`
	position: absolute;
	z-index: 1;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		width: 74%;
		right: 0px;
		bottom: 0px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		right: 0px;
		bottom: 0px;
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		right: 0px;
		bottom: 0px;
    }
`

export const BgMobile = styled.img`
	position: absolute;
	z-index: 1;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		width: 74%;
		right: 0px;
		bottom: 0px;
    }
	
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		right: 0px;
		bottom: 0px;
    }

    // Desktop
    @media (${Views.desktopWidth}) {
		right: 0px;
		bottom: 0px;
    }
`
