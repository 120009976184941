import styled from 'styled-components';
import * as Views from '../config/views';


export const Wrapper = styled.div`
	width: 100%;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;
	color: var(--generalBlack);

	&::-webkit-scrollbar {
			/* -webkit-overflow-scrolling: touch; */
			display: none;
	}


    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		font-size: 16px;
		line-height: 19px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		font-size: 20px;
		line-height: 24px;
    }

	// Desktop
	@media ${Views.desktopWidth} {
		font-size: 22px;
		line-height: 26px;
	}
`

