import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsEnglish: IBanner = {
	year: '2018',
	jobTitle: 'UI/UX Designer',
	logo: '',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Learn English',
	description: 'English language learning app for Russia',
	backgroundColor: '#75DEFF',
	backgroundImage: './img/lenglish/bannerBgEnglish.svg',
	backgroundSize: {
		width: ['720px', '720px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const ENGLISH_DATA: (Data.IElementDataText | Data.IElementDataImage | Data.IElementDataFrame)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Project purpose'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Learn English, is an application that helps Russian-speaking citizens quickly learn the language and leave the country. This tool aims to help people learn the language and translate instantly and accurately. The main target users are young people who care about their future and want to develop, so they actively learn the language to enter the international market',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'Develop an app that will be available at any time helps you learn English in a short period of time and get motivated to learn',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Target audience'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'During the interview, participants cited their inability to find time to learn English as the main reason for not yet learning a language. When observing the participants, it became clear that they spend a lot of time on social media. It became clear that their motivation was not enough, and if they had a tool that motivated them enough to learn, they would gladly use it',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['291px', '225px', '181px'],
			images: [['./img/lenglish/one']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Research details'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'An audit of several competing products revealed weaknesses and opportunities that should be exploited with Learn English',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['312px', '312px', '181px'],
			images: [['./img/lenglish/two']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Idea. Digital wireframes'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I quickly generated ideas to come up with ways to address the shortcomings identified in the competitive audit. I focused on motivating the user and identifying language and interpreter needs. After developing an idea and creating several paper charts, I created the initial design for the English language learning app. My designs focused on selecting a personalized language learning plan and translation assistance',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/lenglish/four']],
		},
		{
			type: 'image',
			block: 'usually',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/lenglish/five']],
			height: ['398px', '350px', '225px'],
		},
	],
	[
		{
			type: 'frame',
			src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FyvCO4dy8y1lhiokb2hICW7%2FLearn%3Fnode-id%3D31%253A3749%26scaling%3Dscale-down%26page-id%3D31%253A3748%26starting-point-node-id%3D31%253A3749',
			text: 'You can view its prototype in Figma'
		}
	],
]
