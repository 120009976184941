import styled from 'styled-components';
import * as Views from '../config/views';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
	width: 100%;
	background-color: #F5F5F5;
	z-index: 100;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		position: fixed;  
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
    }

	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`
export const Header = styled.header`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	align-items: center;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-left: 18px;
		padding-right: 18px;		
		height: 64px;
		max-width: 720px;		 
		.nameHeaderDesktop {
			display: none;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		padding-left: 50px;
		padding-right: 50px;
		height: 82px;
		max-width: 1100px;		
		.nameHeaderDesktop {
			display: none;
		}   
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		height: 82px;
		max-width: 1300px;
		.nameHeaderMobile {
			display: none;
		}  			
	}	
`

export const Logo = styled(Link)`
	display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

	text-decoration: none;
	color: var(--generalBlack);

	cursor: pointer;

	font-family: 'Raleway';
	font-style: normal;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;	
	letter-spacing: 0.02em;

	&:hover {
		opacity: 0.5;
	}

	transition: 0.3s;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		margin-right: 12px;
		width: 45px;
		height: 45px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	
		margin-right: 15px;    
		width: 62px;
		height: 62px;
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		margin-right: 18px;
		width: 62px;
		height: 62px;
	}
`;

export const LogoImage = styled.img`
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		width: 45px;
		height: 45px;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	 
		width: 62px;
		height: 62px;
	}

	// Desktop
	@media (${Views.desktopWidth}) {
		width: 62px;
		height: 62px;
	}
`

export const Name = styled.p`
	cursor: default;
	color: var(--generalBlack);
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		font-size: 16px;
		line-height: 19px;	
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	   
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 22px;
		line-height: 26px;
	}
`

export const NavBar = styled.nav`
	display: flex;
	flex-wrap: wrap;

	margin-left: auto;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		display: none;
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	  
    }

	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`

export const NavLink = styled(Link)`
	margin: 0;
	padding: 0;

	font-family: 'Raleway';
	font-style: normal;
	letter-spacing: 0.02em;

	color: var(--generalBlack);
	font-size: 16px;
	line-height: 19px;	
	text-decoration: none;

	transition: 0.3s;

	&:hover {
		opacity: 0.5;
	}


    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		font-weight: 600;	 
		margin-right: 39px;
		&:last-child {
			margin-right: 0px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	 
		font-weight: 500;
		margin-right: 46px;
		&:last-child {
			margin-right: 0px;
		}  
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		font-weight: 500;
		margin-right: 55px;
		&:last-child {
			margin-right: 0px;
		}
	}
`

export const BurgerButton = styled.button<{ active: boolean }>`
	position: relative;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;

	letter-spacing: 0.02em;

	color: var(--generalBlack);
	padding: 0;
	border: none;
	padding-left: 26px;
	background-color: transparent;
	margin-left: auto;

	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		&::before {
			position: absolute;
			content: '';

			top: 0;
			left: 0;
			
			width: 22px;
			height: 20px;

			background-image: ${props => props.active ? `url('./img/burger_close.svg')` : `url('./img/burger.svg')`};
			background-repeat: no-repeat;
			background-size: cover;
			transition: 0.1s cubic-bezier(0.95, 0.05, 0.795, 0.035);
		}
	}

    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 	  
		display: none;
    }
	
	// Desktop
	@media (${Views.desktopWidth}) {
		display: none;
	}	
`;

export const Menu = styled.div`
	position: absolute;
	z-index: 2;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 28px;

	height: 285px;
	width: 100%;
	padding-top: 35px;
	padding-left: 39px;
	background-color: #f5f5f5;
	list-style-type: none;
	transition: .2s ease-in-out;
`;

export const MenuItem = styled(Link)`
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-decoration: none;
	color: var(--generalBlack);
`