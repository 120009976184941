import React, { FC } from 'react';
import * as Styled from './styled';
import { IPrimary } from './types';
import { One } from './One';
import { Two } from './Two';
import { Half } from './Half';

export const Primary: FC<IPrimary> = ({
	$options,
	$fill,
	$stroke,
	$shadow,
	$format,
	$height,
	$images,
	$openView
}) => {
	return (
		<Styled.Primary option={$options}>
			{$options === 'oneImg' && (<One $fill={$fill} $stroke={$stroke} $shadow={$shadow} $format={$format} $height={$height} $image={$images[0]} $openView={$openView} />)}
			{$options === 'twoImg' && (<Two $fill={$fill} $stroke={$stroke} $shadow={$shadow} $format={$format} $height={$height} $images={$images} />)}
			{$options === 'halfImg' && ($images.map((image, i) => { return (<Half key={`half_${i}`} $fill={$fill} $stroke={$stroke} $shadow={$shadow} $format={$format} $height={$height} $image={image} />)}) )}
		</Styled.Primary>
	);
}
