import React from 'react';
import { UNEED_DATA, bannerPropsUneed } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Uneed = () => {
	return (
		<Template 
			data={UNEED_DATA}
			Banner={Banner}
			bannerProps={bannerPropsUneed}
		/>
	)
}
