import React, { FC } from 'react';
import * as Styled from './styled';
import { IElement } from './types';

export const Element: FC<IElement> = ({ $title, $subtitle = null, children }) => {
	return (
		<Styled.Element>
			<Styled.LeftBlock>
				<>
					{$title}
					{$subtitle}
				</>
			</Styled.LeftBlock>
			<Styled.RightBlock>
				{children}
			</Styled.RightBlock>
		</Styled.Element>
	);
}
