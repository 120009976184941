import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Colors } from './components/config/Colors';
import { Fonts } from './components/config/Fonts';
import { World } from './components/config/World';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Wrapper } from './components/Wrapper/Wrapper';
import { Main } from './components/Main/Main';
import { Cases } from './components/Cases/Cases';
import { Illustration } from './components/Illustration/Illustration';
import { Contacts } from './components/Contacts/Contacts';
import { Radiology } from './components/articles/Radiology';
import { Hub } from './components/articles/Hub';
import { Eccommerce } from './components/articles/Ecommerce';
import { Rgp } from './components/articles/Rgp';
import { Lenglish } from './components/articles/Lenglish';
import { Analitycs } from './components/articles/Analitycs';
import { UtkoEmail } from './components/articles/UtkoEmail';
import { Utkonos } from './components/articles/Utkonos';
import { Uneed } from './components/articles/Uneed';
import { Bigdata } from './components/articles/Bigdata';

export const App = () => {
  return (
    <Wrapper>
		<World />
		<Colors />
		<Fonts />
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="cases" element={<Cases />} />
				<Route path="art" element={<Illustration />} />
				<Route path="contacts" element={<Contacts />} />
				<Route path="radiology" element={<Radiology />} />
				<Route path="hub" element={<Hub />} />
				<Route path="radiology-e-commerce" element={<Eccommerce />} />
				<Route path="radiology-report" element={<Rgp />} />
				<Route path="english-school" element={<Lenglish />} />
				<Route path="analytic-system" element={<Analitycs />} />
				<Route path="utkonos-email" element={<UtkoEmail />} />
				<Route path="utkonos-delivery" element={<Utkonos />} />
				<Route path="uneed" element={<Uneed />} />
				<Route path="bigdata" element={<Bigdata />} />
			</Routes>
			<Footer />
		</BrowserRouter>
    </ Wrapper>
  );
}
