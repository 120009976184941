import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Views from '../config/views';

export const Box = styled.div<{ color: string }>`
	width: 100%;
	height: 200px;
	overflow: hidden;
	position: relative;


	background-color: ${({ color }) => color};

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-top: 64px; 
		height: 683px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		height: 410px;
		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		height: 410px;
	}	
`


export const Banner = styled.div`
	position: relative;
	height: 100%;
	margin: 0 auto;
	/* overflow: hidden; */

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-top: 27px; 
		width: 343px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		padding-top: 30px; 
		width: 750px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 30px; 
		width: 865px;
	}	
`


export const Bread = styled(Link)`
	position: absolute;
	z-index: 3;
	padding-left: 21px;
	text-decoration: none;
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.02em;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--generalBlack);
	&::before {
		position: absolute;
		content: '';
		left: 0;
		top: -2px;
		width: 18px;
		height: 18px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url('./img/arrowBackCases.svg');
	}

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		top: 27px;
		left: 0px;
		font-size: 14px;
		line-height: 16px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		top: 26px;
		left: 0px;
		font-size: 12px;
		line-height: 14px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		top: 26px;
		left: -166px;
		font-size: 12px;
		line-height: 14px;
	}	
`

export const Job = styled.p`
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;
	position: relative;
	z-index: 2;
	white-space: pre;
	letter-spacing: 0.02em;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: var(--generalDarkGray);	

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;		
		text-align: right;
		margin-bottom: 103px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		text-align: right;
		padding-right: 22px;
		margin-bottom: 23px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		text-align: right;
		padding-right: 22px;
		margin-bottom: 23px;
	}	
`

export const LinkLogo = styled.a<{
	heightD: string;
	heightT: string;
	heightM: string;
	widthD: string;
	widthT: string;
	widthM: string;
}>`
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 2;
	display: block;
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		width: ${({ widthM }) => widthM};
		height: ${({ heightM }) => heightM};
		margin-bottom: 28px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		width: ${({ widthT }) => widthT};
		height: ${({ heightT }) => heightT};
		margin-bottom: 33px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: ${({ widthD }) => widthD};
		height: ${({ heightD }) => heightD};
		margin-bottom: 33px;
	}	
`

export const Logo = styled.img<{
	heightD: string;
	heightT: string;
	heightM: string;
	widthD: string;
	widthT: string;
	widthM: string;
}>`
	position: relative;
	z-index: 2;
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		width: ${({ widthM }) => widthM};
		height: ${({ heightM }) => heightM};

	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		width: ${({ widthT }) => widthT};
		height: ${({ heightT }) => heightT};
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: ${({ widthD }) => widthD};
		height: ${({ heightD }) => heightD};
	}	
`

export const LogoEmpty = styled.div<{
	heightD: string;
	heightT: string;
	heightM: string;
	widthD: string;
	widthT: string;
	widthM: string;
}>`
	position: relative;
	z-index: 2;
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		width: ${({ widthM }) => widthM};
		height: ${({ heightM }) => heightM};

	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		width: ${({ widthT }) => widthT};
		height: ${({ heightT }) => heightT};
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: ${({ widthD }) => widthD};
		height: ${({ heightD }) => heightD};
	}	
`

export const Title = styled.p`
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 2;
	font-family: 'Source Serif Pro';
	font-style: normal;

	letter-spacing: 0.02em;

	color: var(--generalBlack);


	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		width: 300px;
		font-weight: 700;
		font-size: 38px;
		line-height: 46px;
		margin-bottom: 14px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		width: 430px;
		font-weight: 700;
		font-size: 56px;
		line-height: 60px;
		margin-bottom: 17px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 530px;
		font-weight: 700;
		font-size: 56px;
		line-height: 60px;
		margin-bottom: 17px;
	}	
`

export const Description = styled.p`
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 2;
	font-family: 'Raleway';
	font-style: normal;

	letter-spacing: 0.02em;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--generalBlack);

	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {
		font-size: 22px;
		line-height: 28px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		max-width: 80%;
		font-size: 28px;
		line-height: 33px;  
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 80%;
		font-size: 28px;
		line-height: 33px;
	}	
`

export const Bg = styled.div<{
	img: string;
	heightD: string;
	heightT: string;
	heightM: string;
	widthD: string;
	widthT: string;
	widthM: string;
}>`
	position: absolute;
	z-index: 1;
	background-repeat: no-repeat;
	background-image: ${({ img }) => `url(${img})`};
	background-size: cover;
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		bottom: 0;
		width: ${({ widthM }) => widthM};
		height: ${({ heightM }) => heightM};
		
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		top: 0;
		right: 0;
		
		width: ${({ widthT }) => widthT};
		height: ${({ heightT }) => heightT};
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		top: 0;
		right: 0;

		width: ${({ widthD }) => widthD};
		height: ${({ heightD }) => heightD};
	}
`