import styled from 'styled-components';
import * as Views from '../../../config/views';

export const List = styled.div`
	display: flex;
	flex-direction: column;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {        
		margin-left: 25px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		margin-left: 45px;
    }
    // Desktop
    @media (${Views.desktopWidth}) {
		margin-left: 55px;
    }
`