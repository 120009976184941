import React from 'react';
import { createGlobalStyle } from 'styled-components';

export const Colors = createGlobalStyle`
	:root {
	// General colors
	--generalWhite: #ffffff;
	--generalBlack: #1E232A;
	--generalDarkGray: #45494F;
	--generalGray: #898C91;
	--generalBlue: #0066FF;

	// Additional colors
	--addBlue: #DCF5FB;
	--addLightBlue: #D4E1FA;
	--addBlueContrast: #C9E2F4;
	--addBlueThin: #E9ECF3;

	--addGreenContrast: #E6F8CA;

	--addPurpleContrast: #ECD2F5;
	--addPurpleThin: #E8E3EB;

	--addYellowContrast: #FFF4D0;
	--addLightOrange: #FDE8CF;
	}
`;
