import React, {FC} from 'react';
import * as Styled from './styled';
import { IWrapper } from './types';

export const Wrapper: FC<IWrapper> = ({ children }) => {
	return (
		<Styled.Wrapper>
			{children}
		</Styled.Wrapper>
	)
}
