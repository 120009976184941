import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsEcom: IBanner = {
	year: '2019',
	jobTitle: 'Lead Designer',
	logo: './img/ecom/bannerLogoRadiology.svg',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Personal area / e-commerce',
	description: 'Online store for e-learning courses',
	backgroundColor: '#D4E1FA',
	backgroundImage: './img/hub/bannerBgRadiology.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const ECOM_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Task'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Our main problem was that all the electronic courses were on different one-page sites, and it was very intimidating and confusing for users. Also, in order for the buyer to be able to make a payment he had to go through a complicated way and this was a long uncomfortable communication with the sales department because there was no personal account',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Testing'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After launching the finished product, I conducted remote moderated testing with users. The purpose of our testing was to find out what kind of difficulties users might encounter when using our site',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#F2F2F2',
			stroke: '',
			format: 'webp',
			height: ['465px', '421px', '226px'],
			images: [['./img/ecom/one']],
			openView: ['']
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'Registration process'
			},
			subtitle: {
				type: 'secondary',
				text: 'the registration process for a new user took more than 4 minutes!'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'The understanding is as follows: to simplify the registration process to users a quick way to register and log in, without unnecessary and lengthy checks',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'Site Navigation'
			},
			subtitle: {
				type: 'secondary',
				text: 'the user was not always able to find the filters and catalog'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'In order for users to find the right section intuitively, we need to revise the filter displays and navigation to training courses',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'System operation'
			},
			subtitle: {
				type: 'secondary',
				text: 'user session was often interrupted, errors occurred'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Having fixed this bug, we have tested our portal on the backend and fixed the bugs',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'Checkout'
			},
			subtitle: {
				type: 'secondary',
				text: 'impossibility to change the settings of the selected course during the payment process'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'In order for the user to easily use the settings of the selected course, the checkout must be completely redesigned',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Developed a desktop and mobile version. Checkout and personal account for administrator and user',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/ecom/two']],
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/ecom/three']],
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'go to the portal'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'We have almost doubled our sales and improved the usability of the site. Now in the personal office user can monitor the learning process, get feedback, as well as quickly buy a new course of interest',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'The process of improving our service is continuous, we continued to work on it, but today the service has changed',
					links: [],
					bolds: []
				},
			],
			link: 'edu.tele-med.ai' 
		},
	],
]
