import React, { FC } from 'react';
import * as Styled from './styled';
import { ITwo } from './types';

export const Two: FC<ITwo> = ({
	$fill,
	$stroke,
	$shadow,
	$height,
	$format,
	$images 
}) => {
	return (
		<Styled.Two>
			<Styled.TwoLeft fill={$fill} border={$stroke} shadow={$shadow} >
				<Styled.TwoLeftImg img={$images[0]} format={$format} heightD={$height[0]} heightT={$height[1]} heightM={$height[2].split('|')[0]} />
			</Styled.TwoLeft>
			<Styled.TwoRight fill={$fill} border={$stroke} shadow={$shadow} >
				<Styled.TwoRightImg img={$images[1]} format={$format} heightD={$height[0]} heightT={$height[1]} heightM={$height[2].split('|')[1]} />				
			</Styled.TwoRight>
		</Styled.Two>
	);
}
