import styled from 'styled-components';
import * as Views from '../config/views';

export const Box = styled.div`
	width: 100%;
	border-top: 1px solid var(--generalBlack);

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   

	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
	}	
`

export const EndBanner = styled.div`
	margin: 0 auto;
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-top: 32px;
		height: 233px;
		width: 342px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		height: 187px;
		width: 760px;
		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		height: 187px;
		width: 865px;
	}	
`

export const Img = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	
	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		padding-top: 32px;
		height: 128px;
		width: 277px;
		background-image: url('./img/endBanner_m.webp');
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		height: 187px;
		width: 760px;
		background-image: url('./img/endBanner_t.webp');
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		height: 187px;
		width: 864px;
		background-image: url('./img/endBanner_d.webp');
	}	
`
