import styled, { css } from 'styled-components';
import * as Views from '../../../../../config/views';

export const Secondary = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		height: 228px; 
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		height: 419px;
    }
	// Desktop
	@media (${Views.desktopWidth}) {
		height: 419px;
	}
`

export const Box = styled.div<{ shadow: string }>`
	margin: 0 auto;
	padding: 0;
	background-repeat: no-repeat;
	background-size: cover;
	${({ shadow }) => shadow && css`
		box-shadow: ${shadow};
	`}

	background-image: url('./img/dropBgSecondary.webp');

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		padding-top: 8px;
		padding-left: 1px;
		width: 343px;
		height: 228px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		padding-top: 15px;
		padding-left: 1px;
		width: 627px;
		height: 419px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		padding-top: 15px;
		padding-left: 1px;
		width: 627px;
		height: 419px;
	}
`

export const Img = styled.img<{ image: string, format: string }>`
	min-height: 100%;
	background-repeat: no-repeat;
	background-size: contain;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		width: 341px;
		height: 220px;
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_m.${props.format}")`}; 
		`}		
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {
		width: 625px;
		height: 402px;
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_t.${props.format}")`}; 
		`}		
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 625px;
		height: 402px;
		${props => props.image && props.format && css`
			background-image: ${`url("${props.image}_d.${props.format}")`}; 
		`}
	}
`