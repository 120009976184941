import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Views from '../config/views';

export const Wrapper = styled.div`
	width: 100%;
	border-bottom: 2px solid #000;
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {
		padding-top: 64px;
		margin-bottom: 3px;    
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		margin-bottom: 7px;   
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 11px;
	}	
`

export const Contacts = styled.div`
	position: relative;
	margin: 0 auto;

	.contactsPhotoMob {
		position: absolute;
	}

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {
		padding-left: 20px;
		max-width: 375px;  
		margin-bottom: 16px;
		padding-top: 42px;
		padding-bottom: 74px;
		padding-right: 20px;  
		.contactsPhotoMob {
			left: 14px;
			bottom: 265px;
			width: 98px;   
			display: block;
		}
    }
	@media (max-width: 370px) {   
		.contactsPhotoMob {
			left: 45px;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		padding-left: 54px;
		max-width: 1100px;  
		margin-bottom: 24px;
		padding-top: 100px;
		padding-bottom: 125px;
		.contactsPhotoMob {
			display: none;
		}
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 1300px;
		margin-bottom: 24px;
		padding-top: 108px;
		padding-bottom: 135px;
		.contactsPhotoMob {
			display: none;
		}
	}	
`

export const Title = styled.p`
	margin: 0;
	padding: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	letter-spacing: 0.02em;
	font-weight: 700;
	
	color: var(--generalBlack);

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {
		font-size: 38px;
		line-height: 46px;	   
		margin-bottom: 16px;  
		.contactsBr {
			display: block;
		}
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		margin-bottom: 24px; 
		font-size: 56px;
		line-height: 60px;
		.contactsBr {
			display: none;
		}			
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		margin-bottom: 24px;
		font-size: 56px;
		line-height: 60px;
		.contactsBr {
			display: none;
		}	
	}	
`

export const Description = styled.p`
	margin: 0;
	padding: 0;

	letter-spacing: 0.02em;
	
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	color: var(--generalBlack);	


    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		max-width: 335px;
		margin-bottom: 38px;
		font-size: 22px;
		line-height: 26px; 
    }
    // Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {  
		max-width: 484px;
		margin-bottom: 38px;  
		font-size: 22px;
		line-height: 30px;
    }

	// Desktop
	@media (${Views.desktopWidth}) {
		max-width: 614px;
		margin-bottom: 41px;
		font-size: 22px;
		line-height: 30px;
	}	
`

export const Photo = styled.img`
	position: absolute;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {     
		display: none;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {     
		display: block;
		width: 121px;
		right: 232px;
		top: 114px;
	}
	@media (min-width: 835px) and (max-width: 945px) {    
		width: 110px;
		right: 150px;
		top: 114px;
	}
	@media (min-width: 768px) and (max-width: 835px) {    
		width: 110px;
		right: 120px;
		top: 120px;
	}
	@media (min-width: 768px) and (max-width: 835px) {    
		width: 110px;
		right: 90px;
		top: 120px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 131px;
		right: 232px;
		top: 114px;
		display: block;
	}
`

export const ContactBox = styled.div`
	display: flex;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
		flex-direction: column;
		height: 117px;
		justify-content: space-between;
		padding-left: 76px; 
		margin-bottom: 113px;
    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		width: 261px;    
		justify-content: space-between;
		margin-bottom: 104px;
    }
    // Desktop
	@media (${Views.desktopWidth}) {
		width: 261px;
		justify-content: space-between;
		margin-bottom: 113px;
    }
`

export const ContactItem = styled.div`
	display: flex;
	position: relative;

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   

    }
    // Tablet
    @media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) { 
		&:not(:last-child)::after {
			position: absolute;
			content: '';
			height: 21px;
			width: 2px;
			background-color: #D9D9D9;
			right: -22px;
		}
    }
    // Desktop
	@media (${Views.desktopWidth}) {
		&:not(:last-child)::after {
			position: absolute;
			content: '';
			height: 21px;
			width: 2px;
			background-color: #D9D9D9;
			right: -22px;
		}
    }
`

export const ContactLink = styled.a`
	margin: 0;
	padding: 0;

	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.02em;
	text-decoration: none;
	color: var(--generalBlue);


	&:hover {
	opacity: 0.7;
	}

	transition: 0.3s;	

    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) { 
		padding-left: 60px;
		font-size: 18px;
		line-height: 30px;	
    }
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
		font-size: 16px;
		line-height: 20px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 16px;
		line-height: 20px;
	}
`

export const ContactDescr = styled.p`
	margin: 0;
	padding: 0;
	
	letter-spacing: 0.02em;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;

	color: var(--generalBlack);
	
    // Mobile
    @media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {  
		display: none;
		font-size: 18px;
		line-height: 30px;	
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   
		font-size: 16px;
		line-height: 30px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		font-size: 16px;
		line-height: 30px;
	}
`

export const CvButton = styled.a`
	display: flex;
    align-items: center;
    justify-content: center;

	text-decoration: none;
	cursor: pointer;
	background-color: transparent;
	
	border: 1px solid var(--generalBlack);
	transition: 0.3s;
	text-transform: uppercase;



	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {    
		margin-right: auto;
		width: 100%;
		border-radius: 12px;

		height: 68px;
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {   		
		width: 200px;
		height: 68px;

		border-radius: 12px;
	}
	// Desktop
	@media (${Views.desktopWidth}) {
		width: 200px;
		height: 68px;

		border-radius: 12px;
		border: 1px solid var(--generalBlack);
		&:hover {
			transform: translateY(-5px);
		}
	}
`

export const CvButtonText = styled.p`
	position: relative;
	margin: 0;
	padding: 0;
	font-family: 'Raleway';
	font-style: normal;

	letter-spacing: 0.085em;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;		
	
	color: var(--generalBlack);	
	margin-right: 10px;
`

export const CvArrow = styled.p`
	width: 17px;
	height: 17px;
	background-image: url('../img/arrow_download.svg');
	background-repeat: no-repeat;
	background-size: contain;

	// Mobile
	@media (${Views.mobileWidthStart}) and (${Views.mobileWidthEnd}) {   
	}
	// Tablet
	@media (${Views.tabletWidthStart}) and (${Views.tabletWidthEnd}) {    
	}
	// Desktop
	@media (${Views.desktopWidth}) {
	}
`

