import React from 'react';
import * as Styled from './styled';

export const Footer = () => {
	return (
		<Styled.Wrapper>
			<Styled.Footer>
				<Styled.Name>Alina&nbsp;<br />Kubrak</Styled.Name>
				<Styled.Links>
					<Styled.Item target='_blank' href='https://www.linkedin.com/in/alina-shesterikova/'>Linkedin</Styled.Item>
					<Styled.Item target='_blank' href='https://t.me/alina_igolkina'>Telegram</Styled.Item>
				</Styled.Links>
			</Styled.Footer>
		</Styled.Wrapper>
	)
}
