import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsBig: IBanner = {
	year: '2021',
	jobTitle: 'Senior Design Expert',
	logo: '',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Big Data',
	description: 'A crazy portal for big data products',
	backgroundColor: '#CCBFFF',
	backgroundImage: './img/uneed/bannerBgBeeline.svg',
	backgroundSize: {
		width: ['670px', '630px', '375px'],
		height: ['412px', '410px', '300px'],
	}
}

export const BIG_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Preparing'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Beeline is a huge brand with a huge amount of big data for every taste. My task was to create a portal to promote and sell our products based on this same data',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'To demonstrate the idea of how the portal works and why it is needed. As usual I put together two storyboards. The big one, which tells how the user comes to our portal. A small one, where I showed in more detail how the portal works. This helped to demonstrate the idea and identify the problem which we wanted to solve by creating this product',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['305px', '305px', '263px'],
			images: [['./img/bigdata/one']],
			openView: ['']
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Target audience'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I conducted quantitative research and additionally interacted with the business teams, which helped me compile portraits of users. They were the owners of large and medium-sized businesses, as well as their assistants, who want to get rid of problem areas with the help of business integrators',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#6C7371',
			stroke: '',
			format: 'webp',
			height: ['305px', '294px', '263px'],
			images: [['./img/bigdata/two']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Prototypes'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Before starting to develop full-fledged design layouts, I assembled prototypes so that our team would have an understanding of the overall functionality and user flow of our portal. After demonstrating the prototype in work, I made some changes according to our technical and business constraints',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#6C7371',
			stroke: '',
			format: 'webp',
			height: ['337px', '314px', '176px'],
			images: [['./img/bigdata/three']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I created a portal with a listing of solutions and pages about the team. I used an atomic approach to creating components after which we worked with development to create a number of design tokens that made creating new pages quick',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/bigdata/four']],
			openView: ['']
		},
		{
			type: 'image',
			block: 'usually',
			options: 'oneImg',
			fill: '',
			stroke: '',
			height: ['398px', '365px', '678px'],
			format: 'webp',
			images: [['./img/bigdata/five']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'go to the portal'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'I have consolidated all the big-data solutions on the portal. Previously, information on solutions was only available in presentations. Now the search has become convenient, and finding any business solution will be convenient and pleasant for our client. The portal is under development and a number of other works are planned, such as: implementation of smart search, demo of innovative technologies, conducting various kinds of usability testing, etc',
					links: [],
					bolds: [11, 12, 13]
				},
			],
			link: 'bigdata.beeline.ru'
		},
	],
]
