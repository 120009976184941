interface IArtImgs {
	path: string;
	pathMobile: string;
	alt: string;
}

export const ART_IMGS: IArtImgs[] = [
	{path: './img/img1.webp', pathMobile: './img/img1Mob.webp', alt: 'image one'},
	{path: './img/img2.webp', pathMobile: './img/img2Mob.webp', alt: 'image two'},
	{path: './img/img3.webp', pathMobile: './img/img3Mob.webp', alt: 'image three'},
	{path: './img/img4.webp', pathMobile: './img/img4Mob.webp', alt: 'image four'},
	{path: './img/img5.webp', pathMobile: './img/img5Mob.webp', alt: 'image five'},
	{path: './img/img6.webp', pathMobile: './img/img6Mob.webp', alt: 'image six'},
	{path: './img/img7.webp', pathMobile: './img/img7Mob.webp', alt: 'image seven'},
	{path: './img/img8.webp', pathMobile: './img/img8Mob.webp', alt: 'image eight'},
	{path: './img/img9.webp', pathMobile: './img/img9Mob.webp', alt: 'image nine'},
	{path: './img/img10.webp', pathMobile: './img/img10Mob.webp', alt: 'image ten'},
	{path: './img/img11.webp', pathMobile: './img/img11Mob.webp', alt: 'image eleven'},
	{path: './img/img12.webp', pathMobile: './img/img12Mob.webp', alt: 'image twelve'},
	{path: './img/img13.webp', pathMobile: './img/img13Mob.webp', alt: 'image thirteen'},
	{path: './img/img14.webp', pathMobile: './img/img14Mob.webp', alt: 'image fourteen'},
	{path: './img/img15.webp', pathMobile: './img/img15Mob.webp', alt: 'image fiveteen'},
	{path: './img/img16.webp', pathMobile: './img/img16Mob.webp', alt: 'image sixteen'},
	{path: './img/img17.webp', pathMobile: './img/img17Mob.webp', alt: 'image seventeen'},
	{path: './img/img18.webp', pathMobile: './img/img18Mob.webp', alt: 'image eighteen'},
	{path: './img/img19.webp', pathMobile: './img/img19Mob.webp', alt: 'image nineteen'},
	{path: './img/img20.webp', pathMobile: './img/img20Mob.webp', alt: 'image twenty'},
	{path: './img/img21.webp', pathMobile: './img/img21Mob.webp', alt: 'image twenty one'},
	{path: './img/img22.webp', pathMobile: './img/img22Mob.webp', alt: 'image twenty two'},
	{path: './img/img23.webp', pathMobile: './img/img23Mob.webp', alt: 'image twenty three'},
	{path: './img/img24.webp', pathMobile: './img/img24Mob.webp', alt: 'image twenty four'},
	{path: './img/img25.webp', pathMobile: './img/img25Mob.webp', alt: 'image twenty five'},
	{path: './img/img26.webp', pathMobile: './img/img26Mob.webp', alt: 'image twenty six'},
	{path: './img/img27.webp', pathMobile: './img/img27Mob.webp', alt: 'image twenty seven'},
	{path: './img/img28.webp', pathMobile: './img/img28Mob.webp', alt: 'image twenty eight'},
	{path: './img/img29.webp', pathMobile: './img/img29Mob.webp', alt: 'image twenty nine'},
	{path: './img/img30.webp', pathMobile: './img/img30Mob.webp', alt: 'image thirty'},
	{path: './img/img31.webp', pathMobile: './img/img31Mob.webp', alt: 'image thirty one'},
]