import React, { FC } from 'react';
import { Grid } from '../layout/Grid/Grid';
import { Block } from '../layout/Grid/Block';
import { Element as Text01 } from '../layout/Grid/elements/Text01/Element';
import { Element as Central01 } from '../layout/Grid/elements/Central01/Element';
import { Element as Images01 } from '../layout/Grid/elements/Images01/Element';
import { Element as Frame01 } from '../layout/Grid/elements/Frame01/Element';
import { Title } from '../layout/Grid/elements/Text01/Title';
import { Subtitle } from '../layout/Grid/elements/Text01/Subtitle';
import { Description } from '../layout/Grid/elements/Text01/Description';
import { EndBanner } from '../EndBanner';
import { ITemplate } from './types';
import * as Styled from './styled';

export const Template: FC<ITemplate> = ({ data, Banner, bannerProps}) => {
	return (
		<Styled.Template>
			<Banner {...bannerProps} />
			<Grid>
				{data.map((block: any, i: any) => {
					return (
						<Block key={`block_${i}`}>
							{block.map((element: any, j: any) => {
								if (element.type === 'primary') {
									return (
										<Text01 
											key={`element_${i}${j}`}
											$title={<Title $type={element.title.type} $text={element.title.text} $isSub={element.subtitle.text}/>}
											$subtitle={element.subtitle.text ? <Subtitle $type={element.subtitle.type} $text={element.subtitle.text} $link={element.link} /> : undefined}
										>
											{element.descriptions.map((descr: any, k: any) => {
												return (
													<Description
														key={`description_${i}${j}${k}`}
														$type={descr.type}
														$text={descr.text}
														$links={descr.links}
														$boldIndex={descr.bolds}
													/>
												);
											})}
										</Text01>
									);
								} else if (element.type === 'secondary') {
									return ((<Central01 key={`central_${i}${j}`} $texts={element.central} />));
								} else if (element.type === 'image') {
									return (
										<Images01
											key={`central_${i}${j}`}
											$block={element.block}
											$options={element.options}
											$fill={element.fill}
											$stroke={element.stroke}
											$shadow={element.shadow}
											$format={element.format}
											$height={element.height}
											$images={element.images}
											$openView={element.openView}
										/>
									);
								} else if (element.type === 'frame') {
									return <Frame01 key={`frame_${i}${j}`} $src={element.src} $text={element.text} />
								}
							})}
						</Block>
					);
				})}
			</Grid>
			<EndBanner />
		</Styled.Template>
	)
}
