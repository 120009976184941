import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsUneed: IBanner = {
	year: '2021',
	jobTitle: 'Senior Design Expert',
	logo: '',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Uneed',
	description: 'App for selecting interesting recommendations based on user preferences',
	backgroundColor: '#CCBFFF',
	backgroundImage: './img/uneed/bannerBgBeeline.svg',
	backgroundSize: {
		width: ['670px', '630px', '375px'],
		height: ['412px', '410px', '300px'],
	}
}

export const UNEED_DATA: (Data.IElementDataText | Data.IElementDataImage | Data.IElementDataFrame )[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Project purpose'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Our team was tasked with redesigning the app to attract more users and improve the interaction experience. Based on in-store feedback, in-depth interviews with current app users, and log analysis',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Problem identification'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: `The positioning and presentation do not form an understanding of uniqueness.  Users see no reason to return to the app. Visual recommendations are not enough to look for recommendations in chat. People don't have a recommendation in its current form, and the fantasy is irrelevant to many users`,
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Interviewing'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Knowing the current issues, we conducted an in-depth interview with 17 participants who love movies, TV shows, and books. We discussed the area closest to the respondent',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['358px', '317px', '235px'],
			images: [['./img/uneed/one']],
			openView: ['']
		},		
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'POV'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Based on our user profile and interviews, I identified a deep unmet need',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#F2F2F2',
			stroke: '',
			format: 'webp',
			height: ['358px', '317px', '153px'],
			images: [['./img/uneed/two']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Testing hypotheses'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: `Based on the client's main pain: "It's hard to find interesting content quickly with non-standard selection criteria and mood" - I developed several ideas and tested 4 product prototypes`,
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['485px', '424px', '194px'],
			images: [['./img/uneed/three']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Test results'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'The test results showed that users tend to the mechanics of Dynamic Selections and Recommendations based on fragments',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'usually',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['278px', '251px', '251px'],
			images: [['./img/uneed/four']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Design creation'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Based on the research, I created high-precision layouts and assembled user flow',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#CDCDD0',
			stroke: '',
			format: 'webp',
			height: ['434px', '407px', '194px'],
			images: [['./img/uneed/five']],
			openView: ['']
		},
		{
			type: 'image',
			block: 'usually',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['803px', '706px', '317px'],
			images: [['./img/uneed/six']],
			openView: ['']
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Based on the research, I created high-precision layouts and assembled user flow. Now the time to create dynamic selections was reduced from 3 minutes to 10 seconds. In addition, new users ~5000 came to us. Implemented the new design',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'frame',
			src: `https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FaSOj7VSKFIbaEIY8xZueOJ%2Fdynamic-selections%3Fpage-id%3D1%253A17%26node-id%3D1%253A3789%26viewport%3D-440%252C467%252C0.18%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A3789`,
			text: 'You can see its prototype in Figma'
		}
	],
]
