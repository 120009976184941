import React, { useState } from 'react';
import { List } from './List/List';
import * as Styled from './styled';

export const Experience = () => {
	const [typeProjects, setTypeProjects] = useState<any>('main');
	const descriptions = [
		'My main design path has been going on for about 7 years now and below I have tried to share it with you',
		'At times I have been found by wonderful people with an interesting product, and here I will boast of some of them',
		'I try to communicate socially and develop not only in a hard direction, but also share my experiences, which hopefully helps the community',
	];
	const typeIndex = typeProjects === 'main' ? 0 : typeProjects === 'side' ? 1 : 2;
	return (
		<Styled.ExperienceBox>
			<Styled.Experience>
				<Styled.ExperienceTitle>My professional experience</Styled.ExperienceTitle>
				<Styled.ChoiceBox>
					<Styled.Choice>
						<Styled.Item active={typeProjects === 'main'} onClick={() => setTypeProjects('main')}>
							<Styled.ItemText>the main way</Styled.ItemText>
						</Styled.Item>
						<Styled.Item active={typeProjects === 'side'} onClick={() => setTypeProjects('side')}>
							<Styled.ItemText>side projects</Styled.ItemText>
						</Styled.Item>
						<Styled.Item active={typeProjects === 'activities'} onClick={() => setTypeProjects('activities')}>
							<Styled.ItemText>activities</Styled.ItemText>
						</Styled.Item>
					</Styled.Choice>
				</Styled.ChoiceBox>
				<Styled.Description>{descriptions[typeIndex]}</Styled.Description>
				<List type={typeProjects} />
			</Styled.Experience>
		</Styled.ExperienceBox>
	);
}
