import React, { FC } from 'react';
import { text } from 'stream/consumers';
import * as Styled from './styled';
import { IElement } from './types';

export const Element: FC<IElement> = ({ $texts }) => {
	return (
		<Styled.Element>
			{$texts.map((text: string, i: number) => {
				return (
					<Styled.Item key={`${text}_${i}`}>
						{text}
					</Styled.Item>
					);
				})}
		</Styled.Element>
	);
}