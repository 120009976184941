import React from 'react';
import { ECOM_DATA, bannerPropsEcom } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Eccommerce = () => {
	return (
		<Template 
			data={ECOM_DATA}
			Banner={Banner}
			bannerProps={bannerPropsEcom}
		/>
	)
}
