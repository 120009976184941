import * as Data from '../types';
import { IBanner } from '../../Banner/types';

export const bannerPropsRadiology: IBanner = {
	year: '2019',
	jobTitle: 'Lead Designer',
	logo: './img/radiology/bannerLogoRadiology.svg',
	logoSize: {
		width: ['188px', '188px', '133px'],
		height: ['47px', '47px', '33px'],
	},
	title: 'Radiology of Moscow',
	description: 'A convenient portal for doctors and patients',
	backgroundColor: '#D4E1FA',
	backgroundImage: './img/radiology/bannerBgRadiology.svg',
	backgroundSize: {
		width: ['630px', '630px', '375px'],
		height: ['410px', '410px', '619px'],
	}
}

export const RADIOLOGY_DATA: (Data.IElementDataText | Data.IElementDataImage)[][] = [
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Product problems and solutions'
			},
			subtitle: {
				type: 'primary',
				text: 'I participated in the design of the portal for Radiology Moscow (Department of Health). It was the most important and big project for me, I learned a lot, especially I worked closely with the development team and product managers. Many thanks to them for their work'
			},
			descriptions: [
				{
					type: 'primary',
					text: `In this project I worked as a lead designer, developed UI/UX, as well as the entire design system of the company's services`,
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'The main problem with the Radiology portal was a very outdated design (as with many resources with governmental overtones). Visually, the portal was in no way associated with the radiology brand. The service itself had a heavy and unfriendly site architecture, the search did not produce the results you were looking for, important articles were on one-page sites, etc., in general there was a lot of work',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Research'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Before we started designing the portal, we conducted interviews with stakeholders to understand what important points to consider in the design and what they want to improve in the product',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'For further work I chose several types of research',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['285px', '289px', '226px'],
			images: [['./img/radiology/first']],
			openView: ['']
		},
		{
			type: 'image',
			block: 'primary',
			options: 'halfImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['229px', '230px', '226px'],
			images: [['./img/radiology/two']],
			openView: ['']
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'Quantitative'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'secondary',
					text: 'Collected metrics and statistics from the last site',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'primary',
			title: {
				type: 'secondary',
				text: 'Qualitative'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'secondary',
					text: 'Conducted several interview sessions with our users. Made a list of questions to better understand the user and learn about his preferences, how he thinks, what is important to him in using the portal',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Test'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After gathering all the important data, I went about planning and generating ideas',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'Conducted a card study to fix the architecture of the site. I asked our test users to arrange our section cards in a way that made sense to them',
					links: [],
					bolds: [1, 2, 3]
				},
				{
					type: 'primary',
					text: 'Identified the value proposition by making a list of all the benefits, highlighting the most valuable features and connecting these features to our users',
					links: [],
					bolds: [1, 2, 3]
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '#F2F2F2',
			stroke: '',
			format: 'webp',
			height: ['324px', '289px', '135px'],
			images: [['./img/radiology/three']],
			openView: ['']
		},
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Prototypes'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'After working out the architecture, I made a navigation map and worked through the digital page prototypes',
					links: [],
					bolds: []
				},
				{
					type: 'primary',
					text: 'In the study I tracked on our users how they cope with the tasks. During testing, it turned out that most users do not always manage to find important sections. so i decided to put such sections as science, education, and medicine - on the main page, in the tab list. I also made a combined navigation on the site, I applied fixed and dropdown navigation, in addition I used navigation in the footer',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'twoImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['471px', '410px', '245px|245px'],
			images: [['./img/radiology/four1', './img/radiology/four2']],
			openView: ['']
		}
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'UI components and KV'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'At the time of the design, I was simultaneously working on creating the component UI library and rendering the KEY VISUAL',
					links: [],
					bolds: [14, 15, 18, 19, 20]
				},
				{
					type: 'primary',
					text: 'Based on research, I chose the welcoming and joyful KEY VISUAL. Since doctors have to deal with difficult cases, I decided to cheer them up with my light and friendly style',
					links: [],
					bolds: []
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['560px', '497px', '218px'],
			images: [['./img/radiology/five']],
			openView: ['']
		},	
		{
			type: 'image',
			block: 'primary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			height: ['659px', '497px', '218px'],
			images: [['./img/radiology/six']],
			openView: ['./img/radiology/sixOpen', '3542px']
		},		
	],	
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Frames'
			},
			subtitle: {
				type: '',
				text: ''
			},
			descriptions: [
				{
					type: 'primary',
					text: 'At the time of design, I used the philosophy of graceful degradation based on our research. In the first iteration, I designed the desktop version, after which I started developing the mobile version',
					links: [],
					bolds: [10, 11]
				},
			],
			link: ''
		},
		{
			type: 'image',
			block: 'secondary',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/radiology/seven1'], ['./img/radiology/seven2']],
		},
		{
			type: 'image',
			block: 'usually',
			options: 'oneImg',
			fill: '',
			stroke: '',
			format: 'webp',
			images: [['./img/radiology/eight']],
			height: ['569px', '569px', '371px'],
		},
	],
	[
		{
			type: 'primary',
			title: {
				type: 'primary',
				text: 'Summary'
			},
			subtitle: {
				type: 'link',
				text: 'go to the portal'
			},
			descriptions: [
				{
					type: 'primary',
					text: 'Our portal has increased attendance by ~70%, as well as feedback from our visitors show that it has become easy and convenient for them to use the portal. In addition, we have strengthened our brand and visual style',
					links: [],
					bolds: []
				},
			],
			link: 'tele-med.ai'
		},
	],
]
