import React, { FC } from 'react';
import * as Styled from './styled';
import { IBlock } from './types';

export const Block: FC<IBlock> = ({ children }) => {
  return (
	<Styled.Block>
		{children}
	</Styled.Block>
  )
}
