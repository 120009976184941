import React from 'react';
import { HUB_DATA, bannerPropsHub } from './scheme';
import { Template } from '../Template';
import { Banner } from '../../Banner';

export const Hub = () => {
	return (
		<Template 
			data={HUB_DATA}
			Banner={Banner}
			bannerProps={bannerPropsHub}
		/>
	)
}
