import React from 'react';
import * as Styled from './styled';

export const Contacts = () => {
	return (
		<Styled.Wrapper>
			<h1 className="VisuallyHidden">Alina Kubrak Contacts</h1>
			<Styled.Contacts>
				<Styled.Title>Alina&nbsp;<br className="contactsBr" />Kubrak</Styled.Title>
				<Styled.Description>UI/UX designer with a strong 7+ years experience who can help you and your product, text me! :)</Styled.Description>
				<Styled.Photo src="img/alina_photo.webp" alt="Alina Kubrak Photo" />
				<Styled.Photo className="contactsPhotoMob" src="img/alina_photo.webp" alt="Alina Kubrak Photo" />
				<Styled.ContactBox>
					<Styled.ContactItem>
						<Styled.ContactLink target="_blank" href="https://www.linkedin.com/in/alina-shesterikova/">Linkedin</Styled.ContactLink>
					</Styled.ContactItem>
					<Styled.ContactItem>
						<Styled.ContactLink target="_blank" href="https://t.me/alina_igolkina">Telegram</Styled.ContactLink>
					</Styled.ContactItem>
					<Styled.ContactItem>
						<Styled.ContactLink target="_blank" href="https://habr.com/ru/users/AlinaZykina/posts/">Habr</Styled.ContactLink>
					</Styled.ContactItem>
				</Styled.ContactBox>
				<Styled.CvButton href='/Kubrak_Alina_CV.pdf' download>
					<Styled.CvButtonText>CV</Styled.CvButtonText>
					<Styled.CvArrow />
				</Styled.CvButton>
			</Styled.Contacts>
		</Styled.Wrapper>
	)
}
